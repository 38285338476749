/*******************************************************************************
** @Author:          Major <Tbouder>
** @Email:          Tbouder@protonmail.com
** @Date:          Wednesday 27 March 2019 - 11:55:52
** @Filename:        App.css
**
** @Last modified by:    Tbouder
** @Last modified time:    Wednesday 22 January 2020 - 11:05:09
*******************************************************************************/

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Montserrat:700,800,900");

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f7fc;
  color: #494949;
  overflow-x: hidden;
}

h1 {
  float: left;
  width: 100%;
  font-family: Barlow, sans-serif;
  color: #273138;
  line-height: 120%;
}

* {
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

img {
  /*max-width: 100%;*/
  height: auto;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
::selection {
  background: #8e54e9;
  color: #ffffff;
  text-shadow: none;
}
::-moz-selection {
  background: #8e54e9;
  color: #ffffff;
  text-shadow: none;
}
input[type="text"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.App {
  font-family: -apple-system, "BlinkMacSystemFont", "Open Sans", sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.relative {
  position: relative;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}

* {
  border: none;
}
@font-face {
  font-family: "Roboto";
  src: url("./ressources/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./ressources/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd";
  src: url("./ressources/fonts/CircularStd-Medium.ttf") format("truetype");
}
.mainReact {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #f5f7fc;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.spaceBetween {
  justify-content: space-between;
}
.alignCenter {
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.mainReactMenu {
  display: flex;
  flex-direction: column;
}
.widthFit {
  width: fit-content;
  max-width: 50%;
}

.modalBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  position: relative;
  z-index: 1000;
  display: flex;
  max-height: 70%;
  width: 60%;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-radius: 12px;
  background-color: #ffffff;
  overflow: auto;
}
.modal.noPadding {
  padding-left: 50px;
  padding-right: 50px;
}
.modal::-webkit-scrollbar {
  display: none;
}

.modalClose {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.modalPicture {
  margin: 20px;
  max-height: 20vh;
  max-width: 20vw;
}
.modalTitle {
  margin: 15px;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
}
.modalSubtitle {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.flex {
  display: flex;
  flex: 1;
}

.center {
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.mainBodyContainer {
  margin: 0 auto;
  width: calc(100% - 250px);
  height: 100%;
  /* position:      relative; */
  margin-left: 250px;
  /* margin-top:      200px; */
}
.bodyContainer {
  background: #f5f7fc;
  padding: 20px 6vw;

  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.noPaddingTop {
  padding-top: 0;
}
.bodyContainerAlt {
  background: #f5f7fc;
  /* padding-left:    6vw; */
  /* padding-right:    6vw; */
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.bodyContainerTopper {
  background: #ffffff;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 4vh;
  border-bottom: 1px solid #ced6dd;
}
.bodyContainerInner {
  background: #f5f7fc;
  padding-left: 6vw;
  padding-right: 6vw;
  margin: 0 auto;
  width: 100%;
  /* height:        100%; */
  /* overflow-x:      auto; */
  position: relative;
}
.bodyLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.blur {
  filter: blur(10px);
}
.bodyContainer.containerFlex {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* min-width: 1400px; */
  height: 100%;
}
.bodyContainer.filter {
  padding-right: 4vw;
}
.containerWithFilter {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.bodyPeriod {
  font-size: 12px;
  font-weight: 400;
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
  color: #606060;
  padding-bottom: 1vh;
}
.bodyTag {
  font-size: 16px;
  font-weight: 800;
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
  color: #848484;
  background: transparent;
}
.bodyTitle {
  font-size: 26px;
  font-weight: 800;
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
  color: #000000;
  background: transparent;
  border-bottom: 1px dashed transparent;
}
.bodyTitleEditable {
  font-size: 26px;
  font-weight: 800;
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
  color: #000000;
  background: transparent;
  border-bottom: 1px dashed transparent;
}
.bodyTitleEditable::after {
  position: absolute;
  margin-left: 8px;
  width: 23px;
  height: 23px;
  max-width: 23px;
  max-height: 23px;
  content: "";
  background-image: url("./ressources/images/penEdit.svg");
  background-size: contain;
  display: none;
  margin-top: 5px;
}
.bodyTitleEditable:hover.bodyTitleEditable::after {
  display: unset !important;
}

.bodyTitleEditor {
  font-size: 26px;
  font-weight: 800;
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
  color: #000000;
  background: transparent;
  border-bottom: 1px dashed #d3dade;
  outline: 0px solid transparent;
  font-weight: 800;
}

.bodyDivider {
  margin-top: 6vh;
}

.bodyShellContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  padding: 2vh 2vw;
  border-radius: 6px;
  margin-top: 4vh;
  position: relative;
}
.bodyShellContainer.noMargin {
  margin-top: 0px;
}

.bodyShell {
  background-image: url("./ressources/images/shell_dashboard.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 4vh;
  width: 100vmin;
  height: 50vmin;
  max-width: 60vw;
  max-height: 30vw;
}
.bodyShellNetwork {
  /* background-image:  url('./ressources/images/shell_network.svg'); */
  /* background-repeat:  no-repeat; */
  /* background-size:  contain; */
  width: 90%;
}
.bodyShellComments {
  width: 40%;
}

.bodyShellStats {
  width: 40%;
}

.bodyShellTitle {
  font-size: 20px;
  font-weight: 800;
  font-family: "Roboto";
  color: #848484;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bodyShellButton {
  cursor: pointer;
  background: #6d77dc;
  color: #ffffff;
  border-radius: 20px;
  padding: 1vh 3vw;
  font-size: 16px;
  text-decoration: none;
}
.withHeight {
  min-height: 50px;
  display: flex;
  align-items: center;
}
.bodyShellButtonAlt {
  cursor: pointer;
  background: #6d77dc;
  color: #ffffff;
  border-radius: 20px;
  padding: 1vh 3vw;
  font-size: 16px;
  margin-left: 3vh;
  text-decoration: none;
  text-align: center;
}
.widthFit > a {
  text-decoration: none;
}
.buttonShellButtonAlt {
  position: absolute;
  right: 6vw;
  bottom: 16px;
}

.buttonShellButtonAlt.disabled {
  opacity: 0.6;
}
.buttonShellButtonAlt.disabled > .bodyShellButton {
  cursor: unset;
}

.buttonShellButtonAlt2 {
  position: absolute;
  right: 6vw;
  top: 40px;
}
.buttonShellButtonAlt2 > a {
  text-decoration: none;
}

.bodyFeed {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  margin-bottom: 16vh;
}

.dashboardContainerName {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #606060;
  font-weight: 600;
}
.dashboardCols3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.dashboardBusinessesStatusContainer {
  margin-top: 2vh;
  margin-bottom: 6vh;
}
.dashboardBusinessesStatus {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2vh;
}
.dashboardBusinessesValue {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  color: #000000;
}
.dashboardBusinessesValue > span {
  font-size: 14px;
}
.dashboardBusinessesStatusText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: #707173;
  display: flex;
  align-items: center;
}
.dashboardBusinessesStatusLink {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #6d77dc;
  margin: 0;
}

.dashboardGradeCardOuter {
  margin: 2vh 0;
  width: 48%;
}
.dashboardGradeCard {
  width: 100%;
  background: #ffffff;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 1vh 1vw;
  padding-bottom: 3vh;
}
.dashboardGradeCard:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  bottom: -2em;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1em solid black;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}
.dashboardGradeCardTitle {
  font-size: 16px;
  font-weight: 800;
  font-family: "Roboto";
  color: #000000;
}
.dashboardGradeCardGraduationContainer {
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: row;
}
.dashboardGradeCardGraduation {
  width: 25%;
  margin: 0 1px;
  height: 10px;
}
.dashboardGradeCardGraduation.low {
  background-color: #eaeaea;
}
.dashboardGradeCardGraduation.low.first {
  background-image: linear-gradient(
    to right,
    #d80f5c,
    #e12b59,
    #ea4757,
    #f25f55
  );
}

.dashboardGradeCardGraduation.medium {
  background-color: #eaeaea;
}
.dashboardGradeCardGraduation.medium.first {
  background-image: linear-gradient(
    to right,
    #960f89,
    #a72585,
    #c9537d,
    #da6a79
  );
}
.dashboardGradeCardGraduation.medium.second {
  background-image: linear-gradient(
    to right,
    #d9697a,
    #ec8276,
    #ff9c72,
    #ff9c72
  );
}

.dashboardGradeCardGraduation.high {
  background-color: #eaeaea;
}
.dashboardGradeCardGraduation.high.first {
  background-image: linear-gradient(
    to right,
    #737ac8,
    #717fc6,
    #6e8ac2,
    #6c8fc0
  );
}
.dashboardGradeCardGraduation.high.second {
  background-image: linear-gradient(
    to right,
    #6c8fc0,
    #6a94bd,
    #669fb9,
    #66a4b7
  );
}
.dashboardGradeCardGraduation.high.third {
  background-image: linear-gradient(
    to right,
    #65a4b7,
    #63aab5,
    #5fb5b1,
    #5ebaaf
  );
}

.dashboardGradeCardGraduation.top {
  background-color: #eaeaea;
}
.dashboardGradeCardGraduation.top.first {
  background-image: linear-gradient(
    to right,
    #42847b,
    #428979,
    #439376,
    #439874
  );
}
.dashboardGradeCardGraduation.top.second {
  background-image: linear-gradient(
    to right,
    #439774,
    #439c72,
    #44a66f,
    #44ab6d
  );
}
.dashboardGradeCardGraduation.top.third {
  background-image: linear-gradient(
    to right,
    #44ac6d,
    #45b16b,
    #45bb68,
    #46c066
  );
}
.dashboardGradeCardGraduation.top.fourth {
  background-image: linear-gradient(
    to right,
    #46c166,
    #46c664,
    #47d160,
    #47d55f
  );
}

.dashboardGradeCardTopper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 2vh;
}
.dashboardGradeCardTopper .low {
  color: #d63212 !important;
  font-weight: 600;
  font-size: 13px;
}
.dashboardGradeCardTopper .medium {
  color: #ae2a86 !important;
  font-weight: 600;
  font-size: 13px;
}
.dashboardGradeCardTopper .high {
  color: #23a0b5 !important;
  font-weight: 600;
  font-size: 13px;
}
.dashboardGradeCardTopper .top {
  color: #19cc99 !important;
  font-weight: 600;
  font-size: 13px;
}

.dashboardSubGradeCard {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  padding: 2vh 1vw;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.dashboardSubGradeCardImage {
  max-width: 120%;
  height: 8vh;
}
.dashboardSubGradeCardMainText {
  font-weight: 900;
  font-family: "Roboto";
  font-size: 26px;
  color: #606060;
}
.dashboardSubGradeCardMainText > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
}
.dashboardSubGradeCardAltText {
  padding-top: 0.6vh;
  font-weight: 300;
  font-family: "Roboto";
  font-size: 14px;
  color: #606060;
}
.dashboardSubGradeCardButton {
  cursor: pointer;
  background: #6d77dc;
  color: #ffffff;
  border-style: hidden;
  border-radius: 5px;
  padding: 10px 1vw;
  /* margin:        0 1vw; */
  font-size: 16px;
  margin-top: 20px;
  border: none;
  width: 100%;
}
.dashboardSubGradeCardDotsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dashboardSubGradeCardDotsWrapper {
  margin: 2vh 0.3vw;
  cursor: pointer;
  padding: 2px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.dashboardSubGradeCardDots {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #c3c6c9;
  border: 1.3px solid #c3c6c9;
}
.dashboardSubGradeCardDots.isActive {
  background: #6d77dc;
}
.dashboardRatingComments {
  margin-top: 0.3vh;
  margin-bottom: 0.6vh;
}
.dashboardBottomPost {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.flexWithSpace {
  display: flex;
  height: 100px;
  flex: 1;
}
.cols {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.customRow {
  flex-direction: row;
}
/* icon-house.svg
icon-micro.svg
icon-star.svg */

.postListItemContainer {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.postListItem {
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  padding: 2vh 2vw;
  padding-top: 4vh;
  padding-bottom: 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-top: 2px solid #5a5e67;
  z-index: 1;
  background: #ffffff;
  margin-bottom: 0;
  /* min-height:      calc(100px + 4vh); */
}
.postListItemPoster {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  border-radius: 100px;
  border: 1px solid #dedede;
  width: fit-content;
  max-width: 60%;
  position: relative;
  top: 2.8vh;
  left: -1vw;
  background: #ffffff;
  z-index: 2;
}
.postListItemPosterLogo {
  min-width: 3.4vh;
  height: 3.4vh;
  border-radius: 1.7vh;
  margin-right: 1.2vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  background-repeat: no-repeat;
}
.postListItemPosterName {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
}

.postListParent {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  margin-right: 4px;
  margin-bottom: 10px;
}
.blueText {
  color: #6d77dc;
}
.postListItemInfo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 1vh;
  white-space: nowrap;
  flex-wrap: wrap;
}
.postListItemInfoName {
  font-size: 14px;
  font-weight: 800;
  font-family: "Roboto";
  color: #000000;
  margin-right: 4px;
}
.postListItemInfoDate {
  font-size: 12px;
  font-family: "Roboto";
  color: #000000;
  /* margin-right:    4px; */
}

.postListItemContentContainer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: "Roboto";
  color: #000000;
  /* height: 100%; */
  min-height: 8vh;
}

.postListItemContentMedia {
  height: 100% !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: -2.6vh;
  right: 0;
  border-radius: 4px;
}
.postListItemContentMedia > img {
  max-width: 100%;
  max-height: 100%;
}

.postListItemContentPlus {
  color: #000000;
  font-size: 14px;
  font-family: "Roboto";
}
.postListItemContentPlus > .plus {
  color: #6d77dc;
}

select,
option {
  font-size: 14px !important;
}

.MainContainer {
  position: relative;
  z-index: 1000;
  display: flex;
  max-height: 100%;
  max-width: 80%;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background-color: #ffffff;
  min-width: 650px;
  overflow: hidden;
}

.checkbox {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced6dd;
}

.checkbox img {
  height: 20px;
  width: 20px;
}

.checkbox.checked {
  border: 1px solid black;
}

.containerPost {
  font-size: 14px;
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
}
.containerPost.planif {
  background: #6d77dc11;
}

.absolute {
  position: absolute;
}

.containerPostMain {
  color: #000000;
}
.isAnswer {
  margin-top: 0px;
}

.containerLevel2Comments {
  padding-top: 10px;
  margin-left: 40px;
  padding-left: 10px;
  border-left: 1px solid rgb(176, 176, 176);
}
.containerLevel2Comments.first {
  padding-top: 0px;
}

.iconComments {
  border-radius: 6px;
}

.containerInfo {
  position: relative;
  flex: 1;
  display: flex;
  /* height:   40px; */
  align-content: center;
  justify-content: flex-end;
  margin: 10px 0 10px 0;
}

.bottomRightContainer {
  padding: 0 7px 0 7px;
  display: flex;
  align-items: center;
}

.infoContainer {
  background-color: #595d68;
  color: white;
  border-radius: 3px;
  width: 100px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0px 10px 0 10px; /*0 30px 0 30px;*/
}

.reactionContainer {
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomReaction {
  padding: 0 5px 0 5px;
  font-size: 12px;
  color: grey;
  flex-direction: row;
  display: flex;
}

.bottomLike {
  flex-direction: row;
  display: flex;
  /* min-width:      30%; */
}

.likeContainer {
  cursor: pointer;
  /* flex: 1; */
  display: flex;
  font-size: 12px;
  color: grey;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.likeContainer:first-child {
  margin-left: 20px;
}

.likeContainer > span {
  display: flex;
  align-items: center;
}

.bottomSelectorIcon {
  font-size: 18px;
  margin-right: 5px;
}

.bottomReactionIcon {
  margin-left: -3px;
}

.bottomReactionText {
  margin-left: 5px;
}

.containerMessage {
  padding-left: 50px;
}

.greyTextInfo {
  color: grey;
  padding: 10px 0px 5px 0px;
}

.containerMessageFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nameContainerPost {
  color: #6d77dc;
  font-weight: bold;
  margin-left: 15px;
  padding-top: 8px;
  vertical-align: middle;
}

.placeNameComments {
  font-size: 12px;
  color: rgb(140, 140, 140);
}

.ratingComments {
  margin-top: 3px;
}

.textContainerPost {
  flex-direction: column;
  display: flex;
  margin-top: 15px;
}

.mediaContainer {
  position: relative;
  height: 100px;
  width: 100px;
  margin-right: 15px;
}

.mediaPhoto {
  object-fit: cover;
  border-radius: 10px;
}

.mediaPhotoTwo {
  object-fit: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  padding-bottom: 2px;
  background-color: white;
  border-top-right-radius: 10px;
}

.mediaPhotoTwoOnly {
  object-fit: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  padding-left: 2px;
  background-color: white;
  border-radius: 0 10px 10px 0;
}

.mediaPhotoThree {
  object-fit: cover;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-bottom-right-radius: 10px;
}

.mediaPhotoPlus {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  color: white;
  height: 50px;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}

.mediaSeparator {
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 52px;
  padding-left: 2px;
  background-color: white;
}

.answerProfileIcon {
  border-radius: 100%;
}

.conversationAnswerIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.conversationAnswerIcon {
  margin-right: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

.sendButton {
  background: none;
}

.showOriginTextComments {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  color: blue;
}

.showOriginTextCommentsPointer {
  cursor: pointer;
}

.mainContainerBottomPost {
  background-color: #ffffff;
  /* border-bottom-left-radius: 6px; */
  border-bottom-right-radius: 6px;
}
.mainContainerBottomPost.planif {
  background: #6d77dc11;
}
.containerBottomForBorder {
  /* margin-left: 65px;
  margin-right: 40px; */
  width: calc(100% - 130px);
  margin: auto;
}

.containerBottomBorder {
  border-top: 1px solid rgb(242, 242, 242);
}
.planif .containerBottomBorder {
  border-top: 1px solid #6d77dc33;
}

.containerBottomPost {
  display: flex;
  padding: 10px 45px 10px 45px;
  align-items: center;
  border-bottom: 2px solid rgb(242, 242, 242);
  flex-wrap: wrap;
}

.blueTextComments {
  cursor: pointer;
  font-size: 12px;
  color: blue;
}

.answerContainerBox {
  box-shadow: 0px 0px 5px 1px lightgrey;
  margin: 0 3px 3px 3px;
  /* z-index: 100; */
}

.answerContainerPost {
  flex-direction: row;
  display: flex;
  vertical-align: middle;
  height: 44px;
}

.answerContainerReply {
  padding: 15px 20px 15px 60px;
}

.answerInputComments {
  width: 75%;
  /* height: 35px; */
  margin: 0 10px 0 10px;
  padding-left: 10px;
  /* padding-right: 10px; */
  outline: none !important;
  border: none;
  border-radius: 6px;
}

.bottomSelector {
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.bottomSelector img {
  height: 20px;
  width: 20px;
}
.withBorder {
  border-left: 1px solid #dedede;
}
.withBorderRight {
  border-right: 1px solid #dedede;
}

.removeGifButton {
  margin: 0 5px 0 5px;
  background-color: white;
}

.removeGifButton:hover {
  background-color: #f5f7fc;
}

.gifDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
}

.multipleGifs {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gifBorders {
  border-radius: 15px;
}

.inputHide {
  display: none;
}

.extraBox {
  z-index: 2;
  background-color: white;
}

.pointer {
  cursor: pointer;
}

.selectArrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.5);
}

.selectArrow.open {
  border-bottom: 10px solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.topBoxContainer {
  position: relative;
  display: flex;
  flex: 1;
}

.arrowBox {
  padding: 5px;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.popupPlanif {
  top: 20px;
  right: 0;
  background-color: white;
  padding-bottom: 15px;
  box-shadow: 0px 0px 6px 2px rgba(207, 199, 207, 1);
  cursor: pointer;
}

.popupText {
  margin: 15px 15px 0 15px;
}

.showAnswerButton {
  cursor: pointer;
}

.answerOptions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.extraContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.extraContentSpace {
  margin-right: 15px;
}

.urlPreviewBox {
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.65);
  width: inherit;
  overflow: hidden;
  height: 25px;
}

.urlPreview {
  font-size: 12px;
  color: #545352;
  text-decoration: none;
}

.reactionsIcon {
  display: flex;
}

@media screen and (max-width: 1700px) {
  .containerInfo {
    justify-content: flex-start;
  }
}

.editContainerBox {
  margin: 0 3px 3px 3px;
  min-width: 600px;
}

.editContainerReply {
  padding: 20px 20px 20px 20px;
}

.editContainerPost {
  flex-direction: row;
  align-items: center;
  display: flex;
  vertical-align: middle;
}

.editOptions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.editAbsolute {
  position: absolute;
}

.extraBox {
  z-index: 2;
  background-color: white;
}

.inputHide {
  display: none;
}

.sendButton {
  display: inline-block;
  *display: inline;
  zoom: 1;
  padding: 6px 20px;
  margin: 0;
  cursor: pointer;
  border: 1px solid #bbb;
  overflow: visible;
  font: bold 13px arial, helvetica, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  color: #555;

  background-color: #ddd;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  /* background-image: linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0)); */

  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -ms-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
  background-clip: padding-box; /* Fix bleeding */
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3), 0 2px 2px -1px rgba(0, 0, 0, 0.5),
    0 1px 0 rgba(255, 255, 255, 0.3) inset;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3),
    0 2px 2px -1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.3) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3), 0 2px 2px -1px rgba(0, 0, 0, 0.5),
    0 1px 0 rgba(255, 255, 255, 0.3) inset;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.multipleGifs {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.extraContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.extraContentSpace {
  margin-right: 15px;
  font-size: 14px;
}

.gifDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 5px 0 0;
}

.editRemoveGifButton {
  top: 0;
  right: 0;
  margin: 5px 5px 0 0;
  background-color: rgba(0, 0, 0, 0);
}

.removeButton {
  background-color: rgba(0, 0, 0, 0);
}

.gifBorders {
  border-radius: 15px;
}

.editEditorText {
  margin: 10px;
  padding: 10px;
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 500px;
  overflow: auto;
  border: 1px solid #8c8c8c;
  border-radius: 6px;
}

.editAnswerIcon {
  margin-right: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

.editAnswerIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filtersFilter {
  display: flex;
  flex-direction: column;
  width: 320px;
  border-left: 30px solid #f5f7fc;
  border-right: 30px solid #f5f7fc;
}
.filtersFilterTitle {
  display: flex;
  flex: 1;
  color: #606060;
  font-weight: 600;
  padding-bottom: 5px;
  background-color: #f5f7fc;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ced6dd;
}
.filtersFilterText {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  padding-top: 40px;
  padding-bottom: 15px;
  justify-content: space-between;
}
.filtersFilterDoubleButton {
  display: flex;
  width: 100%;
}
.filtersFilterDoubleButtonData {
  /* display: flex; */
  /* height: auto; */
  height: auto;
  width: 100%;
  padding: 10px;
  white-space: nowrap;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.filtersFilterDoubleButtonData.active {
  background-color: #6d77dc;
  color: #ffffff;
}
.filtersFilterDoubleButton div:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.filtersFilterDoubleButton div:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: none;
}
.filtersSelectContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.filtersSelectContainer > div:first-of-type {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}
.filtersSelectArrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.5);
}
.filtersSelectArrow.open {
  border-bottom: 6px solid rgba(0, 0, 0, 0.5);
  border-top: none;
}
.filtersSelectList {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  flex-direction: column;
  overflow: scroll;
  overflow: hidden;
}
.filtersSelectItem {
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  padding: 5px;
  border-radius: 6px;
}
.filtersSelectItem:hover {
  background-color: rgb(221, 221, 221);
}
.filtersSelectItem > div {
  margin-left: 5px;
}
.filtersSelectItem.active {
  background-color: #6d77dc;
  color: #ffffff;
}
.filtersSelectItem.active:hover {
  background-color: #6d77dc;
  color: #ffffff;
}

.filtersFilterTextInit {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}
.subHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  padding: 12px 6vw;
  position: relative;
}

.subHeaderTop {
  height: 100px;
  display: flex;
  justify-content: space-between;
}

.buttonArea {
  width: 350px;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #606060;
}

.breadcrumbShoki {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #606060;
}

.breadContainer {
  margin-bottom: 15px;
}
.breadContainer.active {
  color: #6d77dc;
}
.breadContainer.false {
  cursor: pointer;
}

.title {
  font-size: 26px;
  font-weight: 800;
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
  color: #000000;
  background: transparent;
  border-bottom: 1px dashed transparent;
}

.bottom {
  height: 33px;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.mainTab {
  cursor: pointer;
  padding: 5px 12px 12px;
  color: #848484;
  font-size: 16px;
  margin-right: 50px;
  margin-top: 12px;
}
.subTab {
  white-space: nowrap;
}

.tabContainer {
  display: flex;
}
.mainTab.active {
  color: #000;
  border-bottom: 4px solid #6d77dc;
  font-weight: 800;
  padding-bottom: 10px;
  margin-bottom: -12px !important;
}

.buttonContainer {
  display: flex;
}
.buttonBody {
  margin: 5px;
}

.Button {
  cursor: pointer;
  width: 250px;
  background: #6d77dc;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ButtonBlack {
  cursor: pointer;
  width: 250px;
  background: #273138;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ButtonBlack > img {
  margin-left: 8px;
}

.headerChecker {
  margin-right: 10px;
}
.headerStepperMainContainer {
  width: 100%;
  height: 80px;
  display: flex;
  background: #eef4fb;
  box-shadow: 0 5px 7px 0 rgba(112, 112, 112, 0.25);
  margin-bottom: 20px;
  position: absolute;
  left: 0px;
  z-index: 10;
}
.headerStepperStarter {
  width: 160px;
  max-width: 160px;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  background: #6d77dc;
  justify-content: flex-end;
  align-items: center;
  padding-right: 35px;
  display: flex;
}
.headerStepper {
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
}
.headerStepper > div {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.headerStepper > .active {
  color: #6d77dc;
}
.headerStepper > .previous {
  color: #6d77dc;
}
.headerAdjuster {
  margin-top: 70px;
}
.headerAdjusterPlus {
  margin-top: 200px;
}

.infoIconHelpPopup {
  padding-right: 5px;
  padding-left: 5px;
  width: 15px;
  height: 15px;
  box-sizing: initial;
}

.overContainerHelpPopup {
  padding: 10px;
  position: absolute;
  margin-left: 7px;
  box-shadow: 0 0 7px 0 rgba(217, 217, 217, 0.5);
  background-color: #ffffff;
}

.imagePopup {
  position: relative;
  max-height: 20px;
  max-width: 20px;
}
.imagePopup > img {
  height: 20px;
  width: 20px;
}
.imagePopup > div {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: absolute;
  bottom: 25px;
  right: calc(50% - 10px);
  align-items: center;
}
.imagePopup > div > div:first-of-type {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  box-shadow: 0 0 7px 0 rgba(217, 217, 217, 0.5);
  color: #ffffff;
  background-color: #000000;
}

.imagePopup > div > div:last-of-type {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #000000 transparent transparent transparent;
}
.linkPreviewContainer {
  border: 1px solid #e1e8ed;
  border-radius: 0.85714em;
  text-decoration: none;
  color: black;
  display: flex;
  margin: 10px 0 10px 0;
  height: 127px;
}

.linkPreviewIcon {
  border-top-left-radius: 0.85714em;
  border-bottom-left-radius: 0.85714em;
}

.linkPreviewInfo {
  border-left: 1px solid #e1e8ed;
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.linkPreviewTitle {
  font-weight: bold;
}

.linkPreviewUrl {
  color: #8899a6;
}

.Select {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 40px;
  border: 1px solid #848484;
  overflow: hidden;
  border-radius: 8px;
}

.Select select {
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 110%;
  height: auto;
  border: 0;
  margin: 0;
  border-radius: 8px;
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: #ffffff;
  color: #848484;
  padding: 10px 30px 10px 10px;
}

.Select::after {
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 2px);
  right: 10px;
  content: "";
  margin-top: -3px;
  display: block;
  background-image: url("./ressources/images/dropdown.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.lds-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.previewTwitterContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fc;
  border: 1px solid #606060;
  padding-bottom: 30px;
}
.previewTwitterError {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  background-color: #f7dada;
}
.previewTwitterError img {
  width: 15px;
  margin-right: 15px;
}
.previewTwitterMain {
  display: flex;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  border: 1px solid #e3e4e5;
  border-radius: 8px;
  padding-top: 20px;
  margin-top: 30px;
}

.previewTwitterMain > div:first-of-type {
  margin-right: 15px;
}

.previewTwitterMain > div:last-of-type {
  width: 100%;
}

.previewTwitterText {
  margin: 20px;
  margin-left: 0px;
  /* word-break: break-word; */
}

.previewTwitterLogo {
  margin-left: 15px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

.previewTwitterTopName {
  font-weight: 600;
}
.previewTwitterTopDate {
  color: #616770;
  font-weight: 400;
}
.previewContent {
  padding-right: 15px;
}

.previewTwitterMediaContainer {
  width: 98%;
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  /* height: 290px; */
  /* width: calc(100% - 20px); */
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.previewTwitterBigMedia {
  width: 98%;
  /* height: 350px;; */
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  /* height: 290px; */
  /* width: calc(100% - 20px); */
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.SoloImgContainer {
  height: 100%;
  width: 100%;
  /* max-width: 275px;
  max-height: 275px; */
}
.DuoImgContainer1 {
  height: 225px;
  width: 250px;
  /* padding-right: 5px */
}
.DuoImgContainer2 {
  height: 225px;
  width: 250px;
}
.Right {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.moreImgContainer1 {
  height: 100%;
  width: 350px;
}
.moreImgContainer2 {
  flex: 1 1 0;
}
.moreImgContainer3 {
  flex: 1 1 0;
}
.moreImgContainer4 {
  flex: 1 1 0;
}

.imgPreview {
  height: 100%;
  width: 100%;
}

.previewTwitterBottomContainer {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.previewTwitterBottomContainer > div {
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: flex-end;
}

.previewTwitterBottomContainer > div img {
  margin-right: 5px;
}
.previewTwitterText {
  display: block;
  width: 100%;
  word-wrap: break-word;
}
.previewFacebookContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fc;
  border: 1px solid #606060;
  padding-bottom: 30px;
}
.previewFacebookError {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  background-color: #f7dada;
}
.previewFacebookError img {
  width: 15px;
  margin-right: 15px;
}

.previewFacebookMain {
  margin-top: 30px;
  width: 500px;
  background-color: #ffffff;
  border: 1px solid #e3e4e5;
  border-radius: 8px;
  padding-top: 20px;
}

.previewFacebookMain > img {
  width: 100%;
}
.previewFacebookMain > video {
  width: 100%;
  outline: none;
}

.previewFacebookText {
  margin: 20px;
  word-break: break-word;
}

.previewFacebookLogo {
  margin-left: 15px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

.previewFacebookTopContainer {
  margin-left: 15px;
}
.previewFacebookTopName {
  color: #365899;
  font-weight: 600;
}
.previewFacebookTopDate {
  color: #616770;
  font-weight: 400;
}

.previewFacebookMediaContainer {
  width: 98%;
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  /* height: 290px; */
  /* width: calc(100% - 20px); */
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.previewFacebookBigMedia {
  width: 98%;
  /* height: 350px;; */
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  /* height: 290px; */
  /* width: calc(100% - 20px); */
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.SoloImgContainer {
  height: 100%;
  width: 100%;
  /* max-width: 275px;
  max-height: 275px; */
}
.DuoImgContainer1 {
  height: 225px;
  width: 250px;
  /* padding-right: 5px */
}
.DuoImgContainer2 {
  height: 225px;
  width: 250px;
}
.Right {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.moreImgContainer1 {
  height: 100%;
  width: 350px;
}
.moreImgContainer2 {
  flex: 1 1 0;
}
.moreImgContainer3 {
  flex: 1 1 0;
}
.moreImgContainer4 {
  flex: 1 1 0;
}

.imgPreview {
  height: 100%;
  width: 100%;
}

.previewFacebookBottomContainer {
  display: flex;
  margin: 20px;
  /* background-color: #606060; */
}
.previewFacebookBottomContainer > div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
}

.previewFacebookBottomContainer > div img {
  margin-right: 5px;
}

.previewFacebookText > p > span > span > span {
  display: block;
  width: 100%;
  word-wrap: break-word;
}
.imgPreview {
  max-width: 100%;
  max-height: 100%;
}

.lastImgContainer {
  position: relative;
  /* border: 1px solid black;  */
  /* width: 200px;
  height: 200px;  */
  background: lightslategray;
  opacity: 0.5;
}
.lastImgFilter {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e3e4e5;
  font-size: xx-large;
}
.imgPreviewLast {
  /* opacity: 0.5; */
  max-width: 100%;
  max-height: 100%;
}
.modalBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  position: relative;
  z-index: 1000;
  display: flex;
  max-height: 70%;
  width: 60%;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  /* padding-left: 10%; */
  /* padding-right: 10%; */
  border-radius: 12px;
  background-color: #ffffff;
  overflow: auto;
}
.modal::-webkit-scrollbar {
  display: none;
}

.modalClose {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.modalTitle {
  margin: 15px;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
}
.modalSubtitle {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.modalImage {
  width: 140px;
  /* max-height:    100px; */
}

.connectAccountMainInfo {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  color: #606060;
  padding-bottom: 15px;
  justify-content: space-between;
}
.connectAccountMainInfo span:first-of-type span:first-of-type {
  cursor: pointer;
  margin-right: 20px;
}
.connectAccountMainInfo span:first-of-type span:last-of-type {
  cursor: pointer;
  margin-left: 20px;
  text-decoration: underline;
}

.connectAccountMainInfo.end {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

/* GLOBAL */
.connectAccountMainContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 50px;
  background-color: #ffffff;
  border: 1px solid #ced6dd;
  border-top: none;
}

.connectAccountHeader {
  font-family: "Roboto";
  display: flex;
  flex: 1;
  background-color: #ffffff;
  box-shadow: 0 0 1px 1px rgba(226, 226, 226);
}

.connectAccountHeaderLast {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #eef4fb;
}
.connectAccountHeaderContainer {
  display: flex;
  flex: 2;
  position: relative;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #606060;
}
.connectAccountHeaderContainer.active {
  background-color: #6d77dc;
  color: #ffffff;
}

.connectAccountHeaderArrow {
  position: relative;
  display: flex;
}
.connectAccountHeaderArrow.nextActive {
  background-color: #6d77dc;
}
.connectAccountHeaderArrow.nextLast {
  background-color: #eef4fb;
}

.connectAccountHeaderArrow > div:first-of-type {
  display: flex;
  height: 39px;
  border-top: 39px solid transparent;
  border-bottom: 39px solid transparent;
  border-left: 30px solid #ced6dd;
}
.connectAccountHeaderArrow.active > div:first-of-type {
  border-left: 30px solid#ffffff;
}
.connectAccountHeaderArrow > div:last-of-type {
  position: absolute;
  height: 39px;
  left: -1px;
  border-top: 39px solid transparent;
  border-bottom: 39px solid transparent;
  border-left: 30px solid #ffffff;
}
.connectAccountHeaderArrow.active > div:last-of-type {
  border-left: 30px solid#6D77DC;
}

/* STEP 1 - Choose Network */
.connectAccountNetworkTitle {
  margin-top: 30px;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
}
.connectAccountNetworkSubtitle {
  margin-top: 10px;
  margin-left: 50px;
  font-size: 14px;
}
.connectAccountNetworkButtonContainer {
  display: flex;
  flex-wrap: wrap;
  /* flex: 1; */
  padding: 50px;
  justify-content: space-around;
}
.connectAccountNetworkButton {
  cursor: pointer;
  display: flex;
  width: 40%;
  margin: 20px;
  margin-top: 40px;
  font-size: 15px;
  color: #606060;
  text-decoration: none;
}
.connectAccountNetworkButton img {
  height: 40px;
  width: 40px;
}
.connectAccountNetworkButtonText {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
}
.connectAccountNetworkButtonText span {
  margin-top: 10px;
}
.connectAccountNetworkButtonText span:first-of-type {
  color: #000000;
  font-weight: 600;
}

/* STEP 2 - Choose Page */
.connectAccountPlaceTextContainer {
  display: flex;
  flex: 1;
  margin-left: 20px;
  flex-direction: column;
}
.connectAccountPlaceTitle {
  font-size: 16px;
  color: #000000;
}
.connectAccountPlaceSubtitle {
  margin-top: 5px;
  font-size: 12px;
  color: #606060;
}

.connectAccountPlaceTop {
  display: flex;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ced6dd;
}

.connectAccountPlaceTop > div {
  display: flex;
  flex: 1;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
}

.connectAccountPlaceButton {
  cursor: pointer;
  display: flex;
  margin-left: auto;
  padding: 10px;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 150px;
  color: #ffffff;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  background-color: #6d77dc;
}
.connectAccountPlaceButton.unselect {
  background-color: #ffffff;
  border: 1px solid #6d77dc;
  color: #6d77dc;
}

.connectAccountPlaceMain {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-x: scroll;
}
.connectAccountPlaceMainBox {
  display: flex;
  width: 50%;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  padding-top: 20px;
}
.connectAccountPlaceMainBox.border {
  border-right: 1px solid #ced6dd;
}
.connectAccountPlaceMainBox.box0 {
  padding-top: 0px;
}
.connectAccountPlaceMainBoxLogo {
  width: 50px;
  height: 50px;
}
.connectAccountPlaceMainBoxLogo img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.connectAccountPlaceBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 70px;
  border-top: 1px solid #ced6dd;
}
.connectAccountPlaceBottom > div {
  padding-right: 50px;
}

/* STEP 3 - Load Data */
.connectAccountLoadMain {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
}
.connectAccountLoadTitle {
  margin-top: 10px;
  font-size: 16px;
  color: #000000;
}
.connectAccountLoadSubtitle {
  margin-top: 5px;
  font-size: 14px;
  color: #606060;
}

.form-row.inline {
  display: -ms-flexbox;
  display: flex;
}
.form-row:first-child {
  margin-top: 0;
}
.form-row {
  width: 100%;
  margin-top: 20px;
}
.form-row.inline .col:not(:last-child) {
  margin-right: 20px;
}
.form-row.inline .col {
  width: 100%;
}
label {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  color: #273138;
  position: relative;
  font-family: Barlow, sans-serif;
}

.mandateAcceptance {
  margin-top: 20px;
}

@font-face {
  font-family: "Roboto";
  src: url("./ressources/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.mainConnect {
  font-family: "Roboto";
  display: flex;
  flex: 1;

  /* background: linear-gradient(to right, #0d47a1 0%,#6d77dc 100%); */
  background-image: url(./ressources/images/background.svg);
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
}

.mainConnectAlt {
  font-family: "Roboto";
  display: flex;
  flex: 1;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
}

.containerConnect {
  display: flex;
  flex-direction: column;
  width: 65%;
  min-height: 100vh;
  height: 100%;
}

.containerConnectLogo {
  width: 100px;
}

/* MultiEditHeader */
.containerConnectHeader {
  display: flex;
  /* flex: 1; */
  height: 130px;
  /* height: 20%; */
  background-image: url(./ressources/images/illu_network.svg);
  background-repeat: no-repeat;
  background-position: center;
  /* background-position: bottom -10px right 50%; */
}
.containerConnectHeaderContainerLogo {
  display: flex;
  flex: 1;
  align-items: flex-start;
  margin-top: 20px;
}
.containerConnectHeaderContainerCat {
  display: flex;
  flex: 1;
  z-index: 10;
  height: calc(100% + 8px);
  justify-content: center;
  align-items: flex-end;
}
.containerConnectHeaderChat {
  width: 50px;
}
.containerConnectHeaderContainerText {
  font-size: 14px;
  display: flex;
  flex: 1;
  margin-top: 20px;
  align-items: flex-start;
  justify-content: flex-end;
}
.containerConnectHeaderContainerTextLink {
  text-decoration: underline;
  cursor: pointer;
}

/* Main */
.containerConnectMain {
  display: flex;
  align-self: center;
  /* flex: 1; */
  width: 100%;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 50px;
}
.containerConnectMainTitle {
  font-size: 20px;
  font-weight: 600;
  width: 60%;
  text-align: center;
}
.containerConnectMainSubtitle {
  /* font-family: 'Roboto'; */
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000;
  text-align: center;
  width: 50%;
}

.containerConnectMainDoubleInputContainer {
  display: flex;
  width: 70%;
}
.containerConnectMainInputContainer {
  display: flex;
  width: 70%;
  margin-top: 20px;
  /* justify-content: center; */
  /* height: 80px; */
  flex-direction: column;
}
.containerConnectMainInputTitle {
  font-size: 14px;
}
.containerConnectMainInputTitlePassword {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.containerConnectMainInputTitlePassword span {
  color: #a8a8a8;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.containerConnectMainInput {
  width: 100%;
  height: 40px;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none !important;
  border: 1px solid #a8a8a8;
  margin-top: 5px;
}
.forgetPassConnectMainInput {
  width: 37%;
}
.forgetPassConnectMainInput.error,
.containerConnectMainInput.error {
  border: 1px solid #e01e43;
}

.containerConnectMainInputContainer.left {
  margin-right: 15px;
}
.containerConnectMainInputContainer.right {
  margin-left: 15px;
}

.containerConnectMainInputError {
  margin-top: 2px;
  font-size: 14px;
  color: red;
}

.containerConnectMainButton {
  display: flex;
  height: 40px;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  outline: none !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #6d77dc;
}

/* Bottom */
.containerConnectBottom {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100px;
  padding-bottom: 20px;
  align-self: flex-end;
}
.containerConnectBottomContainerLogo {
  display: flex;
  align-items: flex-end;
}
.containerConnectBottomContainerLink {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
}
.containerConnectBottomContainerLink span {
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
}
.containerConnectBack {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.containerConnectBack span {
  margin-top: 15px;
  color: #a8a8a8;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

/* Phone */
@media only screen and (max-width: 48em) {
  .containerConnect {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Main */
  .containerConnectMain {
    padding: 20px;
  }
  .containerConnectMainTitle {
    width: 100%;
  }
  .containerConnectMainDoubleInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .containerConnectMainInputContainer {
    display: flex;
    width: 70%;
    margin-top: 20px;
    flex-direction: column;
  }
  .containerConnectMainInputContainer.left {
    margin-right: 0px;
  }
  .containerConnectMainInputContainer.right {
    margin-left: 0px;
  }

  /* Bottom */
  .containerConnectBottom {
    flex-direction: column-reverse;
  }
  .containerConnectBottomContainerLogo {
    margin-top: 20px;
    justify-content: center;
  }
  .containerConnectBottomContainerLink {
    flex-direction: column;
    align-items: center;
  }
  .containerConnectBottomContainerLink span {
    margin-top: 10px;
  }
}

.conversationWrapper {
  display: flex;
  flex: 1;
  margin-bottom: 40px;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
}
.conversationWrapper > div:first-of-type {
  width: 100%;
}

.selectComments {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.modalNext {
  top: 45%;
  right: 15%;
  position: absolute;
  padding: 4px;
  border: 2px solid black;
}

.modalPrev {
  top: 45%;
  left: 15%;
  position: absolute;
  padding: 4px;
  border: 2px solid black;
}

.tableRowItemDashboard.first {
  padding-left: 40px;
}

.tableRowItemDashboard.center {
  text-align: center;
}

.modalContainerDashboard {
  width: 160%;
  display: flex;
  flex-wrap: wrap;
}

.modalContainerItemDashboard {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 5%;
  margin: 20px 0;
}

.modalContainerItemDashboard img {
  height: 30px;
  width: 30px;
}

.modalContainerItemDashboard > div:last-of-type {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modalContainerItemDashboard > div:last-of-type > span:first-of-type {
  font-size: 18px;
  font-weight: 600;
  color: #5bbbef;
}

.modalContainerItemDashboard > div:last-of-type > span:last-of-type {
  font-size: 14px;
  color: #706f70;
  margin-top: 5px;
}

.modalButtonDashboard {
  display: flex;
  cursor: pointer;
  margin: 0px 10px;
  margin-top: 20px;
  padding: 10px 40px;
  font-size: 15px;
  color: #ffffff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #6d77dc;
}

.statsTitle {
  display: flex;
  flex: 1;
  font-size: 16px;
  color: #606060;
  font-weight: 600;
  margin: 40px 0px 20px 0px;
}
.statsTitle > div {
  color: #000000;
  font-weight: 500;
  justify-content: flex-end;
  display: flex;
  flex: 1;
}
.statsTitle > div > div {
  margin-left: 20px;
}
.statsLittleMainContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  min-width: 200px;
  padding: 10px;
  padding-top: 50px;
  padding-right: 20px;
  background-color: #ffffff;
}
.statsLittleMainContainer > div:first-of-type {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 16px;
  color: #606060;
  font-weight: 600;
}
.statsMainContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  min-width: 650px;
  padding: 10px;
  padding-top: 50px;
  padding-right: 20px;
  background-color: #ffffff;
}
.statsMainContainer > div:first-of-type {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 16px;
  color: #606060;
  font-weight: 600;
}
.statsMainContainer span {
  /* margin: 15px 30% 0px 30%; */
  margin: 15px 0 0px 15px;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.statsDoubleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.statsToolTip {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #ced6dd;
  border-radius: 6px;
  padding: 10px;
}

.statsLegend {
  display: flex;
  flex: 1;
  width: 100%;
  /* flex-direction: row; */
  margin: 15px 0px 10px 0px;
  align-items: center;
  justify-content: flex-start;
}

.statsLegendcolor {
  padding: 6px;
  height: 12px;
  width: 12px;
  margin-left: 40px;
  background-color: #57d0db;
}
.statsLegendcolor.second {
  background-color: #6d77dc;
}
.statsLegendcolor.third {
  background-color: #ffbb28;
}

.statsFanIconContainer {
  display: flex;
}
.statsFanIconContainer > div:last-of-type {
  display: flex;
  color: #6d6e71;
  margin-left: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.statsFanIconContainer > div:last-of-type > p:first-of-type {
  color: #6d77dc;
  font-size: 30px;
  font-weight: 600;
}

/* Select */
.statsSelectContainer {
  position: relative;
  outline: none;
  font-size: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 40px;
}
.statsSelectContainer > div:first-of-type {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 30px 10px 10px;
  border-radius: 6px;
  min-height: 38px;
  font-size: 14px;
}
.statsSelectContainer.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.statsCollapse {
  /* position: absolute;
  right: 10px;
  top: 15px;
  display: flex;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.5); */
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 2px);
  right: 10px;
  content: "";
  margin-top: -3px;
  display: block;
  background-image: url("./ressources/images/dropdown.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.statsCollapse.open {
  /* border-top: none; */
  /* border-bottom: 7px solid #6D77DC; */
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 2px);
  right: 10px;
  content: "";
  margin-top: -3px;
  display: block;
  background-image: url("./ressources/images/dropdown.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.statsSelectList {
  z-index: 999;
  position: absolute;
  display: flex;
  flex: 1;
  right: -1px;
  top: 100%;
  font-size: 12px;
  max-height: 300px;
  width: calc(100% + 20px);
  overflow-y: auto;
  flex-direction: column;
  padding: 10px 0px 10px 0px;
  background-color: #ffffff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.statsSelectItem {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  min-height: 30px;
}
.statsSelectItem.checked {
  color: #6d77dc;
}
.statsSelectItem > * {
  margin-left: 10px;
}

/* Donuts */
.donutsPurple {
  stop-color: #b37ac8;
  stop-opacity: 1;
}
.donutsBlue {
  stop-color: #5bccda;
  stop-opacity: 1;
}
.donutsMiddleTextNumber {
  text-anchor: middle;
  fill: #6d77dc;
  font-size: 18px;
}

.statsLegendContainer {
  display: flex;
  flex: 1;
  width: 100%;
  margin: 15px 0px 10px 0px;
  align-items: center;
  justify-content: flex-start;
}

.statsLegendOne {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.statsLegendColorDefault {
  padding: 6px;
  height: 12px;
  width: 12px;
  margin-left: 40px;
  background-color: #57d0db;
}

.statsLegendContainer span {
  margin: 0px 0 0px 15px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.settingsMainContainer {
  display: flex;
  flex-direction: column;
}

.settingsContainerName {
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 16px;
  color: #606060;
  font-weight: 600;
  margin: 20px 0px;
}

.settingsContainerName > div > div {
  margin: 0px;
}
.settingsPasswordContainer  {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px;
  background-color: #ffffff;
}
.settingsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px;
  background-color: #ffffff;
}

.settingsInputLine {
  display: flex;
  flex: 1;
}
.settingsInputLine > div:first-of-type {
  margin-right: 20px;
}
.settingsInputLine > div:last-of-type {
  margin-left: 20px;
}

.settingsInputContainer {
  display: flex;
  flex: 1;
  margin: 20px 0px;
  flex-direction: column;
}
.settingsInputTitle {
  font-size: 14px;
  font-weight: 600;
}
.settingsInput {
  width: 100%;
  height: 40px;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none !important;
  border: 1px solid #a8a8a8;
  margin-top: 10px;
}
.settingsInput.error {
  border: 1px solid #e01e43;
}

.settingsButtonContainer {
  position: relative;
  display: flex;
  flex: 1;
  outline: none;
  justify-content: flex-end;
}

.settingsButton {
  display: flex;
  height: 40px;
  margin: 30px 20px 0px 20px;
  padding: 0px 30px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  outline: none !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #6d77dc;
}
.settingsButton.disable {
  cursor: default;
  background-color: rgba(109, 119, 220, 0.5);
}
.settingsButton.round {
  border-radius: 50px;
}
.settingsButton.noMargin {
  margin-top: 20px;
}

.settingsLineContainer {
  display: flex;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ced6dd;
}
.settingsLineContainer:last-of-type {
  border: none;
}

.settingsLineContainer > div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.settingsLineContainer > div:first-of-type {
  justify-content: flex-start;
}
.settingsLineContainer > div:last-of-type {
  justify-content: flex-end;
}

.settingsLineContainer > div:first-of-type > div {
  margin-right: 10px;
  align-self: flex-start;
}

.settingsLineContainer > div:last-of-type > div {
  margin-left: 10px;
}

.settingsLineContainer > div:last-of-type > img.rotate90 {
  height: 20px;
  transform: rotate(90deg);
}

.settingsLineContainer > div > span {
  font-size: 14px;
}
.settingsName {
  font-weight: 600;
}
.settingsTextYou {
  font-size: 12px;
  color: #6d77dc;
  font-weight: normal;
}
.settingsTextGrey {
  font-size: 14px;
  color: #848484;
}

/* Little Popup */
.settingsLittlePopup {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 45px;
  width: 500px;
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  cursor: default;
  font-weight: 500;
  margin-bottom: 50px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.settingsLittlePopupTitle {
  display: flex;
  justify-content: space-between;
  color: #848484;
  padding-bottom: 10px;
  border-bottom: 1px solid #ced6dd;
}

.settingsLittlePopupTitle img {
  cursor: pointer;
}

.settingsLittlePopupInputContainer {
  display: flex;
  flex: 1;
  margin-top: 20px;
  flex-direction: column;
}

.settingsLittlePopupInputLine {
  display: flex;
  flex: 1;
  width: 100%;
}
.settingsLittlePopupInputLine > div:last-of-type {
  display: flex;
  flex: 2;
  margin-left: 20px;
}
.settingsLittlePopupInputLine > div:first-of-type {
  margin-left: 0px;
}

.settingsLittlePopupInputTitle {
  font-size: 14px;
}
.settingsLittlePopupInput {
  width: 100%;
  padding: 10px;
  outline: none !important;
  border: 1px solid #a8a8a8;
  margin-top: 10px;
}

.settingsLittlePopupInput.error {
  border: 1px solid #e01e43;
}

.settingsLittlePopupTextarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  padding: 10px;
  outline: none !important;
  border: 1px solid #a8a8a8;
  margin-top: 10px;
}

.settingsLittlePopupText {
  margin-top: 5px;
  font-size: 12px;
  color: #848484;
}
.settingsLittlePopupText > span {
  cursor: pointer;
  color: #6d77dc;
}

.settingsSelect {
  position: relative;
  width: 100%;
  border: 1px solid #a8a8a8;
  margin-top: 10px;
  overflow: hidden;
}

.settingsSelect select {
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 110%;
  height: auto;
  padding-left: 15px;
  border-radius: 8px;
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 30px 10px 10px;
}
.settingsSelect.empty select {
  color: #848484;
}

.settingsSelect::after {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 10px;
  content: "";
  margin-top: -3px;
  display: block;
  border-color: transparent;
  border-top-color: #848484;
  border-width: 5px;
  border-style: solid;
  pointer-events: none;
}

.settingsLittlePopupButtonContainer {
  display: flex;
  flex: 1;
  outline: none;
  justify-content: center;
}

.time-picker {
  display: flex;
  align-items: center;
  border: 1px solid #606060;
  border-radius: 8px;
  margin-left: 7px;
  padding: 4px 10px;
  padding-bottom: 7px;
  position: relative;
  width: 170px;
}

.time-picker input {
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  /* padding: 5px 10px; */
}
.time-picker input::-webkit-clear-button {
  display: none;
}
.time-picker input::-webkit-search-results-button {
  display: none;
}
.time-picker input::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time-picker img {
  margin-left: 10px;
  height: 20px;
}
.flexEditor {
  flex-direction: column;
  width: 100%;
}
.headerStepperMainContainer {
  position: relative;
  margin-bottom: 0;
}
/* .publishHeaderLast {
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  color: #6D77DC;
  background-color: #EEF4FB;
  padding-left: 50px;
}
.publishHeaderLast.step2 {
  color: #6D77DC;
  background-color: #EEF4FB;
} */
/* .publishHeaderContainer {
  display: flex;
  flex: 3 0;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color:#EEF4FB;
  color: #6D77DC;
}
.publishHeaderContainer.step2 {
  color: #6D77DC;
  background-color: #EEF4FB;
}
.publishHeaderArrow {
  display: flex;
  background-color: #EEF4FB;
  padding-left: 20px;
} */
/* .publicationArrow{
  height: 40px;
    width: 40px;
    align-self: center;
}
.publicationArrowPurple{
  height: 40px;
  width: 40px;
  align-self: center;
} */
/* .publishHeaderArrow.step2 {
  background-color: #EEF4FB;
}
.publishHeaderText{
  flex-grow: 10;
} */
/* .headerHalfCircle{
  flex-basis: 250px;
  display: flex;
  justify-content: center;
  width: 50%;
    height: 100%;
    background-color: #6D77DC;
    border-bottom-right-radius: 110px;
    border-top-right-radius: 110px;
    border-bottom: 0;
}
.networkCheck{
  height: 20px;
  width: 20px;
  margin: 10px;
} */
/* .publishHeaderArrow > div:first-of-type {
  display: flex;
  height: 39px;
  border-top: 39px solid transparent;
  border-bottom: 39px solid transparent;
  border-left: 30px solid#ffffff;
}

.publishHeaderArrow > div:last-of-type {
  position: absolute;
  height: 39px;
  left: -1px;
  border-top: 39px solid transparent;
  border-bottom: 39px solid transparent;
  border-left: 30px solid#6D77DC;
}
.publishHeaderArrow.step2 > div:last-of-type {
  border-left-color: #EEF4FB;
} */

/* .iconNetwork{
  height: 30px;
  width: 30px;
  margin: 15px;
  align-self: center;
} */
/* Select */
.publishSelectContainer {
  z-index: 999;
  width: 100%;
  font-size: 14px;
  border-radius: 6px;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  color: #8c8c8c;
}
.publishSelectContainer:hover {
  border-color: #6d77dc;
}
.publishPlaceholder {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
}
.publishSelectContainer.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: calc(100% - 60px);
  color: unset;
  border-color: #6d77dc;
  margin-bottom: 20px;
}
.publishSelectContainer > div:first-of-type {
  position: relative;
  /* display: flex; */
  /* width: 100%; */
  /* flex: 1; */
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
}
.publishSelectContainer > div:first-of-type span {
  width: 100%;
}
.publishSelectContainer.open > div:first-of-type {
  box-shadow: 0px 0px 0px 2px #6d77dc;
  color: #6d77dc;
  margin-bottom: 2px;
}

.publishSelectBox {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  color: #4d4d4d;
  text-transform: capitalize;
}
.publishSelectBox > div {
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
}
.publishSelectBox > div > img {
  margin-left: 10px;
  height: 10px;
}
.publishSelectBox.twitter {
  background-color: #dcf7ff;
}
.publishSelectBox.facebook {
  background-color: #e7e1ff;
}
.publishSelectBox.google {
  background-color: #fcc3b4;
}
.publishSelectBox.instagram {
  background-color: #ffdee8;
}

.publishCollapse {
  position: absolute;
  right: 10px;
  /* top: 15px; */
  display: flex;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.5);
}
.publishCollapse.open {
  border-top: none;
  border-bottom: 7px solid #6d77dc;
}

.publishSelectList {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
  margin: 2px;
}
.publishSelectItem {
  display: flex;
  align-items: center;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  min-height: 30px;
}
.publishSelectItem:hover {
  background-color: #8e54e9;
  color: #ffffff;
}
.publishSelectItem.bold {
  font-weight: 600;
}
.publishSelectItem.checked {
  color: #6d77dc;
}
.publishSelectItem > * {
  margin-left: 10px;
}
.publishSelectLine {
  margin: 10px;
  margin-right: 40px;
  margin-left: 20px;
  border-top: 1px solid #dddddd;
}

/* Step 1 */
.publishFirstStepContainer {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 30px;

  background-color: #ffffff;
  border: 1px solid #ced6dd;
  border-top: none;
}

.publishFirstStepTitle {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 40px;
}
.publishFirstStepTitleMiddle {
  display: flex;
  flex: 3;
  padding-right: 20px;
  justify-content: space-between;
}
.publishFirstStepTitleMiddle > div {
  display: flex;
  align-items: center;
}
.publishFirstStepTitleMiddle > div img {
  height: 20px;
}
.publishCounterText {
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.5);
}
.publishCounterText.negative {
  color: #d63212;
}

.publishFirstStepTitleEnd {
  display: flex;
  flex: 3 1;
  justify-content: flex-end;
  margin: 0 10px;
  margin-left: 0px;
  max-width: 400px;
}
.publishFirstStepPreviews {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.publishFirstStepTitleEndItem {
  position: relative;
  display: flex;
  margin-left: 20px;
  align-items: flex-end;
  padding: 5px;
  padding-bottom: 10px;
  border-bottom: 3px solid #ffffff;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.publishWarning {
  position: absolute;
  bottom: 20px;
  left: -3px;
  width: 10px;
}
.publishFirstStepTitleEndItem.select {
  border-bottom: 3px solid #6d77dc;
}
.publishFirstStepTitleEndItem img {
  height: 20px;
  margin-right: 8px;
}

.publishFirstStepMessageContainer {
  display: flex;
  min-height: 150px;
  /* height: 150px; */
}
.publishFirstStepMessageContainer textarea {
  display: flex;
  flex: 1;
  resize: none;
  border: none;
  padding: 15px;
  font-size: 14px;
  border-radius: 6px;
  outline: none !important;
}
.publishFirstStepMessageContainer > div,
.publishFirstStepMessageContainer label {
  display: flex;
  margin: 10px;
  border: 1px solid #8c8c8c;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.publishFirstStepMessageContainer > div:focus-within,
.publishFirstStepMessageContainer > div:focus,
.publishFirstStepMessageContainer > div:hover,
.publishFirstStepMessageContainer label:hover {
  border-color: #6d77dc;
}
.publishFirstStepMessageContainer > div {
  /* flex:      3; */
  margin: 0 10px;
  margin-left: 0px;
}
.publishFirstStepMessageContainer label {
  flex: 1;
  margin: 0 10px;
  margin-right: 0px;
  cursor: pointer;
  background-color: #f5f7fc;
}
.publishFirstStepMessageContainer label > div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.publishFirstStepMessageContainer input {
  display: none;
}
.publishFirstStepPicture {
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 5px;
}
.publishFirstStepPicture > img {
  position: absolute;
  top: 10px;
  right: 10px;
}

.publishFirstStepPicture div:first-of-type {
  display: flex;
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.publishFirstStepPicture div:last-of-type {
  display: flex;
  /* overflow:    hidden; */
  word-break: break-all;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 3px;
  align-self: center;
}
.publishFirstStepPicture video {
  width: 100%;
  height: 100%;
  max-height: 115px;
}
.publishFirstStepEmptyPicture {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;

  font-size: 14px;
  color: #8c8c8c;
}
.publishFirstStepMessageContainer label:hover {
  border-color: #6d77dc;
}
.publishEmptyPreview {
  width: 100%;
  padding: 20px;
  color: rgb(77, 77, 77, 0.5);
  border: 1px solid #606060;
  background-color: #f5f7fc;
}
.publishEmptyPreviewInner {
  width: 60%;
  margin: auto;
  border: 1px solid rgb(221, 222, 226);
  border-radius: 6px;
  padding: 10px;
}
.publishEmptyPreviewTop {
  display: flex;
  color: #4d4d4d;
  align-items: center;
  margin-bottom: 10px;
}
.publishEmptyPreviewTop > div:last-of-type {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.publishEmptyPreviewTop > div:last-of-type > span:first-of-type {
  font-weight: 600;
}
.publishEmptyPreviewInner > p {
  font-size: 14px;
}

.publishDateSelectLine {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.publishDateSelectRound {
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 15px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 0px 1px #000000;
  cursor: pointer;
}
.publishDateSelectRound.active {
  background-color: #6d77dc;
}

.publishButtonContainer {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 25px;
}

.publishButton {
  display: flex;
  height: 40px;
  margin-left: 20px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  color: #6d77dc;
  font-size: 15px;
  outline: none !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #6d77dc;
}
.publishButton.disabled {
  cursor: no-drop;
}

.publishButton.full {
  color: #ffffff;
  font-size: 15px;
  outline: none !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #6d77dc;
}

/* Step 2 */
.publishSecondStepContainer {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 30px;
}
.publishSecondStepTitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
}

.publishSecondStepText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.publishSecondStepButtonContainer {
  display: flex;
}
.editor {
  width: 100%;
  height: 100%;
}
.editorText {
  font-weight: 400;
  background-color: transparent;
  color: #212121;
  display: block;
  min-width: 0%;
  /* width:        100%; */
  outline: none;
  margin: 0;
  padding: 10px;
  overflow-wrap: anywhere !important;
  overflow-y: auto;
  position: relative;
}
.editorMentionNetworkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0;
  padding-top: 5px;
  border-bottom: 1px solid #9d9d9d;
}
.editorMentionNetwork {
  width: 25%;
  text-align: center;
  border-bottom: 3px solid transparent;
}
.fullfaker {
  /* position:      absolute;
  top:        0;
  left:        0;
  right:        0;
  bottom:        0; */
}
.editorMentionNetwork.isSelected {
  border-bottom: 3px solid #6d77dc;
}
.editorMentionContainer {
  align-items: center;
  /* display: flex; */

  background: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  /* position: fixed; */
  z-index: 10;
  width: 260px;
  box-shadow: 0 0 1px 1px rgba(226, 226, 226);
}
.editorMentionLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.editorMentionLoader div {
  width: 35px;
  height: 35px;
  margin: 0;
}
.editorMentionSuggestion {
  align-items: center;
  display: inline;
  padding: 4px 8px;
}
.editorMentionSuggestion > p {
  font-size: 10px;
  font-style: italic;
  margin-top: 4px;
}
.divTag:not(last-of-type) {
  border-bottom: 1px solid #ddd;
}
.verifiedIcon {
  width: 12px;
  margin-right: 5px;
}

.hashtag {
  color: #6d77dc;
}
.mention {
  color: #6d77dc;
  background-color: #6d77dc22;
}

.publishVariableContainer {
  position: relative;
  margin-top: 10px;
  outline: none;
}
.publishVariableContainer > span:first-child {
  color: #6d77dc;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.publishVariableContainer > div {
  position: absolute;
  min-width: 200px;
  top: 25px;
  padding: 7px 10px;
  background-color: #ffffff;
  border: 1px solid #ced6dd;
}

.publishVariableContainer > div > div {
  display: flex;
  cursor: pointer;
  padding: 7px 0px;
  font-size: 12px;
}

.publishTimePicker {
  position: absolute;
  width: 100%;
  height: 144px;
  bottom: 66px;
  left: 0px;
  margin-bottom: -44px;
}
.publishTimePickerInner {
  background: #ffffff;
  width: 100%;
  height: 122px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  border: 1px solid #606060;
  border-radius: 8px;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.publishTimePickerInnerPart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.publishTimePickerTitle {
  font-weight: 900;
  margin-bottom: 10px;
}

.publishTimePickerInput {
  width: 40px;
  height: 30px !important;
  border: 1px solid #000000 !important;
  text-align: center;
  z-index: 1;
}
.publishTimePickerArrow {
  margin: 0 !important;
  height: 8px !important;
  margin-bottom: 5px !important;
}
.publishTimePickerArrowRevert {
  margin: 0 !important;
  height: 8px !important;
  margin-top: 5px !important;
  transform: rotate(180deg);
}
.timePickerPlaceholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  outline: none;
}
.publishTimePickerArrowContainer {
  display: inline-block;
  position: relative;
  padding: 20px;
  margin: -20px;
}
.userPicture {
  width: 45px;
  height: 45px;
  padding: 2.5px;
}
.divTag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divTag > div {
  display: flex;
}

.absolute {
  background-color: #ffffff;
  z-index: 20;
}

.divTag.hover,
.divTag:hover {
  background: #87cefa;
}
.attachmentContainer {
  max-width: 400px;
  width: 100%;
}

.mainContainer {
  height: 75px;
  width: 125px;
  display: flex;
  flex-direction: column;
}

.header {
  height: 15px;
}

.GifIcon {
  margin: 5px;
}

.answerIcon {
  margin: 5px;
  cursor: pointer;
}
.bigFlexContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.flexContainerVariableLeft {
  display: flex;
}
.flexContainerVariableRight {
  display: flex;
}
.crossContainer {
  margin: 5px;
  display: flex;
}
.textCross {
  margin-right: 5px;
  color: #6d77dc;
  font-weight: 600;
}

.publishEditorContainer {
  width: 80%;
}

.crossInfo {
  margin-left: 10px;
}

.tablePostHistory {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 50px;
  border-radius: 6px;
  background-color: #ffffff;
  border-collapse: collapse;
  box-shadow: 0 0 7px 0 rgba(217, 217, 217, 0.5);
}

.tableRowPostHistory {
  height: 90px;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #4a4a4a;
  cursor: pointer;
}
.tableRowPostHistory.header {
  cursor: default;
  height: 60px;
  padding-left: 10px;
}
.tableRowPostHistory.top {
  border-top: 1px solid #eaeaea;
}
.tableRowPostHistory.color {
  background-color: #fafafa;
}

.tableRowItemPostHistory {
  padding: 10px;
}
.tableRowItemPostHistory.left {
  padding-left: 20px;
}
.tableRowItemPostHistory.right {
  padding-right: 20px;
}
.tableRowItemPostHistory.center {
  text-align: center;
}

.containerPreviewHistory {
  width: 474px;
  font-size: 14px;
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 7px 0 rgba(217, 217, 217, 0.5);
}

.placeContainerHistory {
  display: flex;
  /* height: 100%; */
  width: 50vw;
  max-width: 800px;
  align-content: flex-start;
  flex-wrap: wrap;
}
.placeContainerHistory > div {
  color: #4a4a4a;
  display: flex;
  height: 80px;
  align-items: center;
  padding: 10px;
  flex: 0 0 33.3333%;
  /* flex: 1; */
}

.placeContainerTextHistory {
  color: #4a4a4a;
  font-weight: 600;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin-top: 20px;
  margin-left: 20px;
}

.placePictureHistory {
  border-radius: 3px;
  margin-right: 10px;
}

.historyCalendarContainer {
  display: flex;
  flex: 1;
  /* height:      600px */
}

.calendarPopupBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
}

.calendarPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 20px;
  padding-top: 10px;
  border-radius: 6px;
  background-color: #ffffff;
  border-collapse: collapse;
  max-height: 70%;
  width: 400px;
  overflow-y: auto;
  /* max-width: 70%; */
  /* box-shadow: 0 0 7px 0 rgba(217, 217, 217, 0.5); */
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.calendarPopupOptions {
  display: flex;
  flex-direction: row;
}

.calendarPopupCross {
  cursor: pointer;
  color: #c4c4c4;
  margin-left: 15px;
  /* align-self: flex-end; */
}

.calendarPopupMain {
  display: flex;
  flex: 1;
}

.calendarPopupMain {
  display: flex;
  flex: 1;
}

.calendarPopupLogo img {
  margin-top: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.calendarPopupData {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
}

.calendarPopupOptionsMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calendarPopupTime {
  font-size: 14px;
  color: #c4c4c4;
}

.calendarPopupPlaceName {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}
.calendarPopupTagName {
  margin-left: 4px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
}
.calendarPopupText {
  margin-top: 4px;
  font-size: 14px;
}
.calendarPopupOtherPlace {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}

.calendarPopupError {
  margin-top: 10px;
  font-size: 14px;
}
.messageContainer {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.conversationDate {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  color: #848484;
}
.conversationSeparator {
  width: 100%;
  height: 2px;
  background: #6d77dc;
  margin-top: 40px;
}

.calendarPopupImageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
}
.calendarPopupImageCounter {
  font-size: 14px;
  color: #000000;
  margin-left: 4px;
  margin-bottom: 1px;
}

.historyMenuItem {
  cursor: pointer;
  padding: 5px 12px 12px 12px;
  color: #848484;
  font-size: 16px;
  margin-right: 50px;
}

.historyMenuItem.active {
  color: #6d77dc;
  border-bottom: 4px solid #6d77dc;
  font-weight: 800;
}

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff;
}
.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__withBorder {
  /* display: flex; */
  margin-left: 7px;
  margin-right: 7px;
  padding: 4px 10px;
  padding-bottom: 6px;
  border: 1px solid #606060;
  border-radius: 8px;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  overflow: visible;
  cursor: pointer;
  /* display: inline-block; */
  vertical-align: middle;
  /* padding: 10px; */
  margin-right: 5px;
  outline: none;
}
.SingleDatePickerInput_calendarIcon img {
  height: 20px;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  margin: auto;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__block {
  display: block;
}
.SingleDatePicker_picker {
  z-index: 1;
  /* background-color: red; */
  position: absolute;
  margin-bottom: -44px;
}
.SingleDatePicker_picker__rtl {
  direction: rtl;
}
.SingleDatePicker_picker__directionLeft {
  left: 0;
}
.SingleDatePicker_picker__directionRight {
  right: 0;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.CalendarDay {
  height: 10px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.CalendarDay:active {
  outline: 0;
}
.CalendarDay__defaultCursor {
  cursor: default;
}
.CalendarDay__default {
  /* border: 1px solid #e4e7e7; */
  color: #484848;
  background: #fff;
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  /* border: 1px solid #e4e7e7; */
  color: inherit;
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  /* border: 1px double #e4e7e7; */
  color: inherit;
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848;
}
.CalendarDay__outside:hover {
  border: 0;
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  /* border: 1px solid #eceeee; */
  color: #cacccd;
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848;
}
.CalendarDay__selected_span {
  background: #66e2da;
  /* border: 1px double #33dacd; */
  color: #fff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  /* border: 1px double #33dacd; */
  color: #fff;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #6d77dc;
  /* border: 1px double #00a699; */
  color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  /* border: 1px double #80e8e0; */
  color: #007a87;
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  /* border: 1px double #80e8e0; */
  color: #007a87;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  /* border: 1px solid #cacccd; */
  color: #82888a;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  /* border: 1px solid #e4e7e7; */
  color: rgba(0, 0, 0, 0.5);
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  /* border: 1px double #eceeee */
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  /* border: 1px double #e4e7e7 */
}
.CalendarMonth {
  border: 1px solid #606060;
  border-radius: 8px;
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}
.CalendarMonth_caption {
  color: #000000;
  font-size: 15px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 30px;
  caption-side: initial;
}
.CalendarMonth_caption::first-letter {
  text-transform: uppercase;
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}
.CalendarMonthGrid {
  /* background: #fff; */
  text-align: left;
  z-index: 0;
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
  top: 0px;
}
.CalendarMonthGrid__vertical {
  margin: 0 auto;
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}
.DayPickerNavigation {
  position: relative;
  z-index: 2;
}
.DayPickerNavigation__horizontal {
  height: 0;
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}

/* Arrow button */
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}
.DayPickerNavigation_button__default {
  /* border: 1px solid #e4e7e7; */
  /* background-color: red; */
  color: #000000;
  outline: none;
}

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #000000;
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #000000;
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0;
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 15px;
  line-height: 0.78;
  border-radius: 3px;
  /* padding: 6px 9px */
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%;
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block;
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848;
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2;
}
.DayPicker {
  /* background: #fff; */
  position: relative;
  text-align: left;
}
.DayPicker__horizontal {
  /* background: #fff; */
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker_portal__vertical {
  position: initial;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}
.DayPicker_weekHeader {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  top: 45px;
  z-index: 2;
  text-align: left;
}
.DayPicker_weekHeader *::first-letter {
  text-transform: uppercase;
}
.DayPicker_weekHeader__vertical {
  left: 50%;
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  /* font-size: 14px */
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}
.DateInput__small {
  width: 97px;
}
.DateInput__block {
  width: 100%;
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}
/* Input */
.DateInput_input {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  outline: none;
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
}
.DateInput_input__regular {
  font-weight: auto;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* .DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
  } */
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  /* left: 0px; */
  top: -20px;
  right: -15px;
  z-index: 2;
  stroke: #fff;
}
.DateInput_fangShape {
  fill: #fff;
}
.DateInput_fangStroke {
  stroke: #606060;
  fill: transparent;
}
.networkAddTitle {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 600;
}
/* .networkAddSubtitle, */
.networkAddTitle > p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
}

/* CARD PART */
.networkAddCard {
  display: flex;
  width: 48%;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  text-decoration: none;
  color: #606060;
  background: #ffffff;
  padding: 20px;
  position: relative;
}
.networkAddCard.added {
  cursor: unset;
}
.networkAddCard > div:first-child > img {
  width: 50px;
  height: 50px;
}
.networkAddCard > div:last-child > img {
  margin-top: 20px;
  width: 250px;
  height: 50px;
  align-self: center;
}
.networkAddCard > span {
  margin-left: 25px;
  align-self: center;
}

/* ACCOUNT PART */
.networkAddAccountTop {
  display: flex;
  align-items: center;
}
.networkAddAccountTop > div {
  display: flex;
}
.networkAddAccountButton {
  cursor: pointer;
  display: flex;
  margin-left: auto;
  padding: 10px;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 150px;
  color: #6d77dc;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #6d77dc;
}
.networkAddAccountButton.full,
.networkAddAccountButton:hover {
  background-color: #6d77dc;
  border: 1px solid #6d77dc;
  color: #ffffff;
}
.networkAddAccountBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 70px;
}
.networkAddAccountBottomText {
  font-size: 13px;
  margin-right: 20px;
}
.networkAddAccountMain {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-x: scroll;
  background: #ffffff;
  border: 1px solid #ced6dd80;
  max-height: 400px;
  position: relative;
}

.connectAccountPlaceMainBoxInstagram {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ced6dd80;
}
.connectAccountPlaceMainBoxInstagram:first-child {
  padding-top: 0;
}
.connectAccountPlaceMainBoxInstagram:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.networkAddInstagramConnectButton {
  position: absolute;
  right: 50px;
}

.placeDataMainInfo {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  color: #606060;
  padding-bottom: 1vh;
  text-decoration: underline;
}

/* GLOBAL */
.placeDataMainContainerHeader {
  padding: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #edf0f3;
}

.placeDataMainContainer {
  display: flex;
  flex: 1;
  padding-top: 0px;
  flex-direction: column;
  border-top: none;
}

/* NETWORK */
.placeDataNetworkConnected {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.placeDataNetworkDisconnected {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

.placeDataNetworkButtonContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ced6dd;
  /* margin-bottom:    20px; */
  background: #ffffff;
}

.placeDataNetworkButtonContainer:last-of-type {
  border-bottom: none;
}
.placeDataNetworkButton {
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  font-size: 15px;
  text-decoration: none;
  color: #606060;
  background: #ffffff;
  align-items: center;
  height: 80px;
}
.placeDataNetworkButton.connect.facebook {
  border-left: 4px solid #3b5998;
}
.placeDataNetworkButton.connect.twitter {
  border-left: 4px solid #55acee;
}
.placeDataNetworkButton.connect.google {
  border-left: 4px solid #28b446;
}
.placeDataNetworkButton.connect.instagram {
  border-left: 4px solid #ef3364;
}
.placeDataNetworkButton > div {
  height: 40px;
}

.placeDataNetworkButton img {
  width: 40px;
  height: 40px;
}

.placeDataNetworkButton > span {
  margin-left: 25px;
  margin-right: 10px;
  align-self: center;
}

.networkNameContainer {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 10px;
}

.networkNameContainer span:first-of-type {
  font-weight: bold;
  color: #000000;
}
.placeDataNetworkDisconnectText {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
}
.placeDataNetworkDisconnectText span:first-of-type {
  padding-top: 15px;
  padding-bottom: 10px;
}
.placeDataNetworkDisconnectText span:first-of-type {
  color: #000000;
  font-weight: 600;
}

.placeDataNetworkEndButton {
  display: flex;
  align-items: center;
  /* flex-direction:    column; */
  justify-content: space-between;
  margin-right: 30px;
}
.placeDataNetworkEndButton > div {
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  /* margin-left:    6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.placeDataNetworkEndButton > div img {
  width: 18px;
  max-height: 18px;
}

.placeDataTitleContainer {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 20px;
}
.placeDataTitleContainer > div:first-of-type {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.placeDataTitleContainer a {
  text-decoration: none;
}

.placeDataContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 0px 40px;
}

.placeDataVariableContainer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ced6dd;
}
.placeDataVariableContainer:last-of-type {
  border: none;
}
.placeDataVariableContainer > div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
}
.placeDataVariableContainer > div:first-of-type {
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
}
.placeDataVariableContainer > div:last-of-type {
  justify-content: flex-end;
}
.placeDataVariableContainer > div:last-of-type > div {
  position: relative;
  margin-left: 15px;
}
.placeDataVariableContainer > div:last-of-type > img.rotate90 {
  height: 20px;
  transform: rotate(90deg);
}

.placeDataVariableContainer > div > span {
  color: #848484;
  font-weight: 500;
  font-size: 12px;
}
.placeDataVariableInput {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background: transparent;
  border-bottom: 1px dashed #d3dade;
  outline: none;
}

.networkDataFormLabelContainer {
  margin-top: 50px;
  margin-bottom: 20px;
}
.networkDataFormLabel {
  color: #848484;
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 20px;
}
.networkDataFormLabel.empty {
  margin-top: 0;
  margin-bottom: 0;
}
.networkDataFormDeleter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.networkDataFormSubLabel {
  color: #848484;
  font-size: 13px;
  font-weight: 400;
  margin-right: 6px;
}
.networkDataFormSubLabelDeleteContainer {
  width: 18px;
  max-height: 18px;
}
.networkDataFormSubLabelDelete {
  width: 18px;
  max-height: 18px;
  display: none;
}
.networkDataFormDeleter:hover > div > .networkDataFormSubLabelDelete {
  display: unset;
}
.networkDataFormDeleter:hover > .networkDataFormSubLabel {
  color: #6d77dc;
}

.networkDataFormSelector {
  position: absolute;
  right: 0;
  top: -10px;
  width: 150px;
}
.networkDataFormContainer {
  width: 100%;
  min-height: 100px;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 30px;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
}
.networkDataFormContainerRow {
  width: 100%;
  min-height: 100px;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.networkDataFormContainerColumn {
  width: 100%;
  min-height: 100px;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.networkDataFormCoverContainer {
  position: relative;
  max-width: 675px;
  width: 675px;
  max-height: 250px;
  height: 250px;
}
.networkDataFormCover {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
}
.networkDataFormCoverIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -10px;
  top: 55%;
  cursor: pointer;
}
.networkDataFormCoverOverlay {
  width: 100%;
  height: 95px;
  background: #00000080;
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
}
.networkDataFormCoverContainer:hover .networkDataFormCoverOverlay {
  display: unset;
}
.networkDataFormAvatarContainer {
  position: relative;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.networkDataFormAvatarTitle {
  color: #000000;
  font-size: 14px;
}
.networkDataFormAvatarInnerContainerRadius {
  border-radius: 70px;
  overflow: hidden;
  position: relative;
}

.networkDataFormAvatarInnerContainer {
  width: 140px;
  height: 140px;
  max-width: 140px;
  max-height: 140px;
  position: relative;
}

.networkDataFormAvatar {
  width: 140px;
  height: 140px;
  max-width: 140px;
  max-height: 140px;
  border-radius: 70px;
}
.networkDataFormAvatarOverlay {
  width: 140px;
  height: 140px;
  max-width: 140px;
  max-height: 140px;
  background: #00000080;
  position: absolute;
  margin-top: 85px;
  left: 0;
  display: none;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
}
/* .networkDataFormAvatarContainer:hover .networkDataFormAvatarOverlay, */
.networkDataFormAvatarInnerContainer:hover .networkDataFormAvatarOverlay {
  display: unset;
}
.networkDataFormAvatarIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -13px;
  top: 50%;
}
.networkDataFormHidden {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 101%;
  opacity: 0;
  cursor: pointer;
}
.networkDataButton {
  background: #6d77dc;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}
.networkDataButton.stroke {
  background: transparent;
  border: 1px solid #6d77dc;
  color: #6d77dc;
}
.networkDataButton.ok {
  background: #28b446;
}
.networkDataButton.disable,
.networkDataButton:disabled {
  background: #9d9d9d80;
  cursor: unset;
}
.networkDataFieldName {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #494949;
  margin-bottom: 6px;
}
.networkDataFieldLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #494949;
  margin-bottom: 4px;
}
.networkDataFieldLabel > span,
.networkDataFieldName > span {
  color: #fe0000;
  padding-left: 4px;
}
.networkDataFieldLabel > span.error,
.networkDataFieldName > span.error {
  color: #ff0000;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}
.networkDataChecker {
  display: flex;
  align-items: baseline;
  flex-direction: row;
}
.networkDataChecker > img {
  margin-right: 20px;
  cursor: pointer;
}
.networkDataCheckerDayOn {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  color: #8e54e9;
  width: 120px;
}
.networkDataCheckerDayOff {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  color: #a8a8a8;
  width: 120px;
}
.networkDataCheckerLabel {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #9d9d9d;
  margin-right: 8px !important;
  margin-left: 16px !important;
}
.networkDataTimePicker {
  font-size: 14px;
  padding: 15px 20px;
  width: 80px !important;
  outline: none;
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #a8a8a8;
}
.networkDataTimePickerBigger {
  font-size: 14px;
  padding: 15px 20px;
  width: 100%;
  outline: none;
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #a8a8a8;
}
.networkDataTimePickerAdd {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8e54e9;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.networkDataTimePickerAdd > img {
  margin-right: 10px;
}
.networkDataFieldDescription {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
}
.networkDataTextArea {
  border: solid 1px #848484;
  font-size: 0.9em;
  min-height: 4.5em;
  padding: 20px;
  width: 100%;
  resize: none;
  outline: none;
}

.networkDataSelect {
  border-radius: 2px;
  background: #ffffff;
  font-size: 14px;
  padding: 7px 0px;
  width: 100%;
  outline: none;
  border: 1px solid #f2f2f2;
}
.select__indicator-separator {
  display: none;
}
.select__control {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.select__multi-value {
  background-color: rgba(142, 84, 233, 0.1) !important;
  border: 1px solid #8e54e9;
  box-sizing: border-box;
  border-radius: 2px;
}
.select__multi-value__label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 0px;
  display: flex;
  align-items: center;
  color: #8e54e9 !important;
  padding: 18px 12px !important;
}
.select__multi-value__remove {
  color: #8e54e9;
  border-left: 1px solid #8e54e9;
  border-radius: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.networkDataInputContainer {
  border-radius: 2px;
  background: #ffffff;
  /* padding:      15px 20px; */
  display: flex;
  align-items: center;
  height: 100%;
  /* border:        1px solid #F2F2F2; */
}
.networkDataInput {
  font-size: 14px;
  padding: 15px 20px;
  width: 100%;
  outline: none;
  border: 1px solid #f2f2f2;
  height: 100%;
}
.networkDataInputPrefix {
  background: #f2f2f2;
  box-sizing: border-box;
  padding: 15px 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #7c7c7c;
}
.networkDataFieldSelectorBox {
  margin-bottom: 14px;
}

.networkDataFieldSelectorBox.half {
  width: 50%;
  position: relative;
}
.networkDataFieldSelectorBox.focus {
  background: #dde1f5;
}
.networkDataFormButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 2vh;
  flex-wrap: wrap;
}
.networkDataFormButtonsCancel {
  cursor: pointer;
  color: #6d77dc;
  padding: 1vh 1vw;
  margin-left: 3vw;
  font-size: 16px;
}
.networkInputButton {
  width: fit-content;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  margin-bottom: 1px;
  align-items: center;
  background: #6d77dc;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
}
.networkInputButtonOutline {
  width: fit-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  margin-bottom: 1px;
  align-items: center;
  border: 1px solid #6d77dc;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
}
.networkInputButtonOutline > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6d77dc;
}
.networkInputButtonNone {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #616161;
  margin-left: 16px;
  cursor: pointer;
}
.stickyNetworkContainer {
  width: 100%;
  height: 75px;
  background: #ffffff;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  flex-direction: row;
  padding: 16px 19px;
  margin-bottom: 1px;
  align-items: center;
}
.stickyNetworkContainer.empty {
  background: #6d77dc;
}
.stickyNetworkContainer.disable {
  background: #84848480;
  cursor: unset;
}
.stickyNetworkTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
}
.stickyNetworkStatus {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(31, 31, 31, 0.6);
}
.stickyNetworkEmtpyMessage {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}
.bodyShellButton.error {
  background: #fe0000;
}
.verticalDivider {
  border-right: solid 1px #f5f7fc;
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
}

.soloPlaceTitle {
  margin-top: 40px;
}

.bodyShellButton.inactive {
  background: #cdcdcd;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  font-family: "GoogleFont", Roboto, Helvetica, Arial, sans-serif;
}
[contenteditable]:focus {
  outline: 0px solid transparent;
}

.placeSearchMainContainer {
  display: flex;
  flex: 1;
  margin-top: 20px;
  padding: 0;
  /* overflow:      hidden; */
  align-items: center;
  margin-bottom: 20px;
}
.placeSearchContainer {
  display: flex;
  flex: 1;
  padding: 0;
  overflow: hidden;
  border-bottom: 1px solid #9d9d9d;
  align-items: center;
}
.placeSearchContainer input {
  display: flex;
  flex: 1;
  padding: 7px 0px 7px 10px;
  background-color: #f5f7fc;
  font-size: 14px;
  outline: none;
  border: none;
}

.placeSearchContainer:focus-within {
  border-bottom: 1px solid purple;
}

.placeSearchContainer > img {
  width: 14px;
  height: 14px;
}

.gridViewButtonContainer {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  margin-right: 25px;
}
.gridViewButtonContainer > div:first-child {
  width: 6px;
  height: 6px;
  background-color: #9d9d9d;
  position: absolute;
  top: 0;
  left: 0;
}
.gridViewButtonContainer > div:nth-child(2) {
  width: 6px;
  height: 6px;
  background-color: #9d9d9d;
  position: absolute;
  top: 0;
  right: 0;
}
.gridViewButtonContainer > div:nth-child(3) {
  width: 6px;
  height: 6px;
  background-color: #9d9d9d;
  position: absolute;
  bottom: 0;
  left: 0;
}
.gridViewButtonContainer > div:last-child {
  width: 6px;
  height: 6px;
  background-color: #9d9d9d;
  position: absolute;
  bottom: 0;
  right: 0;
}

.gridViewButtonContainer.grid > div:first-child {
  background-color: #6d77dc;
}
.gridViewButtonContainer.grid > div:nth-child(2) {
  background-color: #6d77dc;
}
.gridViewButtonContainer.grid > div:nth-child(3) {
  background-color: #6d77dc;
}
.gridViewButtonContainer.grid > div:last-child {
  background-color: #6d77dc;
}

.listViewButtonContainer {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.listViewButtonContainer > div {
  width: 20px;
  height: 2px;
  background-color: #9d9d9d;
}
.listViewButtonContainer.list > div {
  background-color: #6d77dc;
}

.placeMasterContainer {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.placeMasterContainer > div:last-of-type {
  display: flex;
  flex: 1;
  width: 100%;
  /* justify-content: center; NON CENTRÉ */
}
.placeSeparator {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.placeSeparatorText {
  display: flex;
  justify-content: center;
  color: #606060;
  width: auto;
  margin-right: 30px;
}
.placeSeparatorText.grid {
  margin-left: 30px;
}

.placeSeparator div:first-of-type,
.placeSeparator div:last-of-type {
  display: flex;
  flex: 8;
  height: 1px;
  border-bottom: 1px solid #e6e7e8;
  width: 100%;
}

.placeCardTopList {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 30%;
  position: relative;
}
.placeCardTop {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 120px;
  border-bottom: 1px solid #000000;
  position: relative;
  border-top-left-radius: 5px;
}
.placeCardTopSelect {
  position: absolute;
  display: flex;
  height: 120px;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}
.placeCardTopHover {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.placeCardTopHover * {
  display: none;
}

.placeCardMenu {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  padding-right: 5px;
}
.placeCardMenu img {
  width: 15px;
}

.placeCardTopBar {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 5px 0px;
}
.placeCardTopBar img {
  height: 15px;
  margin-right: 3px;
}

.placeCardMenuContainer {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  position: absolute;
  top: 20px;
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
}
.placeCardMenuContainer > div {
  margin: 5px;
}
.placeCardMenuContainer img {
  margin-right: 5px;
}

.placeCardBottom {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  color: #606060;
  padding: 10px 20px;
  padding-top: 30px;
}

.placeCardName > div > img {
  display: none;
}
.placeCardName img {
  height: 15px;
  margin: 0px 5px;
}
.placeCardNameInner {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #1a1a1a;
}
.placeCardAddress {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #606060;
  padding-top: 10px;
}
.placeCardSocialContainerList {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.placeCardSocialContainerList > img {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin-right: 2px;
}

.placeCardSocialList {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100%;
}
.placeCardSocialContainer {
  display: flex;
  width: 100%;
  align-items: center;
  position: absolute;
  margin-left: -2px;
  top: 0;
  height: 0;
}
.placeCardSocialContainer > img {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin-right: 2px;
}
.placeCardSocialMoreList {
  justify-content: flex-end;
  margin-right: 5px;
}
.placeCardSocialMoreList > img {
  display: flex;
  height: 24px;
  width: 24px;
  background-color: #ef3364;
  border-radius: 50%;
  cursor: pointer;
}
.placeCardSocialMoreListText {
  color: #de4666;
  font-size: 14px;
}

.placeCardSocialMore {
  /* display: flex;
  flex: 1;
  justify-content: flex-end; */
  margin-right: 20px;
  position: absolute;
  right: 0;
}
.placeCardSocialMore > img {
  display: flex;
  height: 30px;
  width: 30px;
  background-color: #ef3364;
  border-radius: 50%;
  cursor: pointer;
}

.placeCardSocialList > .placeCardSocialInstagram .placeCardSocialList > img {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin-right: 2px;
}

.placeModalButtonContainer {
  display: flex;
  width: 100%;
}

.placeModalButton {
  display: flex;
  flex: 1;
  width: 100%;
  cursor: pointer;
  margin: 0px 10px;
  margin-top: 20px;
  padding: 10px;
  font-size: 15px;
  color: #ffffff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #6d77dc;
}
.placeModalButton.empty {
  color: #6d77dc;
  border: 1px solid #6d77dc;
  background-color: #ffffff;
}

.placeCardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.placeCardsContainerList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.placeCardContainer {
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(112, 112, 112, 0.25);
  margin-bottom: 40px;
  width: 200px;
  margin-right: 40px;
  border-radius: 3px 3px 0px 0px;
}
.placeCardContainer:first-child {
  margin-left: 0;
}
.placeCardContainerMain {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(112, 112, 112, 0.25);
  width: 200px;
  border-radius: 3px 3px 0px 0px;
  overflow: hidden;
}

.placeCardRightList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}
.placeCardMenuListPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeCardMenuListPlaceholder > div {
  background: #9d9d9d;
  height: 5px;
  width: 5px;
  border-radius: 5px;
  margin: 2px;
}
.placeCardMenuList {
  cursor: pointer;
  display: none;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: center;
}
.placeCardMenuList > div {
  cursor: pointer;
  position: relative;
  width: 100%;
}
.placeCardMenuList > div > div {
  display: none;
}
.placeCardMenuList > div:hover > div {
  display: flex;
}
.placeCardMenuList > div:first-child {
  margin-right: 7px;
}
.placeCardMenuList > div:last-child {
  margin-left: 7px;
}
.placeCardRightList:hover .placeCardMenuListPlaceholder {
  display: none;
}
.placeCardRightList:hover .placeCardMenuList {
  display: flex;
}
.placeCardMenuListIcon {
  width: 100%;
}

.addBusinessFloatingButtonContainer {
  position: relative;
}
.addBusinessFloatingButton {
  position: absolute;
  right: calc(6vw - 36px);
  top: -33px;
  z-index: 2;
  cursor: pointer;
}
.addBusinessFloatingButtonInfoBubble {
  position: absolute;
  right: calc(6vw + 32px);
  top: -19px;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition-property: opacity;
  -moz-transition-property: opacity;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-duration: 0.15s;
  -moz-transition-duration: 0.15s;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
}
.addBusinessFloatingButton:hover ~ .addBusinessFloatingButtonInfoBubble {
  opacity: 1;
}

.networkAddFakeUser {
  width: 100%;
  height: 60px;
  background: #6d77dc50;
  margin-top: 80px;
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  padding-left: 90px;
  padding-right: 6vw;
  color: #00000080;
  font-size: 14px;
}
.networkAddFakeUser img {
  height: 35px;
  margin-right: 10px;
}
.networkAddFakeUser span {
  right: 0;
  position: absolute;
  right: 90px;
  text-decoration: underline;
  cursor: pointer;
}

.cancelSearch {
  cursor: pointer;
}

.menu {
  position: fixed;
  height: 100%;
  min-width: 250px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 1px rgba(226, 226, 226, 0.5);
  z-index: 20;
}

.menuContainerLogo {
  display: flex;
  width: 250px;
  height: 200px;
  justify-content: center;
  align-items: center;
  background: #f5f7fc;
  box-shadow: 0px 0px 4px 2px rgba(217, 217, 217, 0.5);
}

.menuLogo {
  width: 165px;
  cursor: pointer;
}

/* Menu Items */
.menuItemSelector {
  height: 100%;
  width: 5px;
  background-color: #ffffff;
}
.menuItemContainer.active .menuItemSelector {
  background-color: #6d77dc;
}
.menuItemContainer {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  cursor: pointer;
  color: inherit;
}
.menuItemContainer.active,
.menuItemContainer:hover {
  color: inherit;
}
.menuItemContainer.disable {
  cursor: inherit;
  opacity: 0.3;
}
.menuVisiretailBackLink {
  background: #8e54e9;
  padding-left: 5px;
  /*margin-top: 9px;*/
  position: relative;
}
.menuVisiretailBackLinkNew {
  position: absolute;
  padding: 6px 10px;
  background: rgba(0, 0, 0, 0.3);
  right: 0;
  top: 0;
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.menuVisiretailText {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}
.menuItemIcon {
  margin-left: 20px;
}
.menuItemContainer span {
  margin-left: 20px;
  font-size: 14px;
}
.menuItemContainer.active {
  background: linear-gradient(
    to right,
    rgba(115, 122, 200, 0.25) 10%,
    #ffffff 75%
  );
}
.menuItemContainer.active span {
  font-weight: 700;
}

.menuItemContainer.sub span {
  margin-left: 35px;
}

.menuItemEnd {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 10px;
}

.alertMenu {
  font-style: italic;
  font-size: 10px;
  color: #6d77dc;
}

.headers {
  display: flex;
  height: 80px;
  width: calc(100% - 250px);
  margin-left: 250px;
  background-color: #ffffff;
  /* position:      fixed; */
  box-shadow: 0px 0px 4px 2px rgba(217, 217, 217, 0.5);
}

.headerRightContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  height: 60px;
  margin: 20px 6vw;
  border-bottom: 1px solid #ced6dd;
  align-items: center;
}
.headerChangeOffer {
  font-weight: 300;
  font-size: 13px;
  color: #6d77dc;
  margin-right: 40px;
}
.headerRightContainer span {
  font-size: 13px;
}
.headerPlaceName {
  font-weight: 800;
}

.headerNameContainer {
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
  /* margin-right: 50px; */
}

.collapseHeader {
  width: 10px;
  height: 6px;
}
.collapseHeader.open {
  transform: rotate(180deg);
}
.collapseHeader.close {
  transform: rotate(180deg);
}

.headerNotif {
  position: relative;
  margin-right: 40px;
  outline: none;
}

.headerNotifHide {
  position: absolute;
  z-index: 999;
  right: 10px;
  width: 300px;
  height: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  overflow-y: scroll;
  box-shadow: 0 0 7px 0 rgba(217, 217, 217, 0.5);
  top: 35px;
  /* right:        200px; */
}

.headerNotifContainer {
  border-radius: 100%;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-left: 16px;
  font-size: 10px;
  font-weight: 400;
  margin-top: -2px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #d63212;
  color: #ffffff;
  padding: 3px;
  height: 15px;
  width: 15px;
}

.headerNotifItem {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  cursor: pointer;
  margin: 10px;
  /* padding: 5px; */
  border-radius: 6px;
}
.headerNotifItem:hover {
  background-color: rgb(221, 221, 221);
}

.headerNotifItem div {
  display: flex;
}

.headerNotifItem span:last-child {
  margin-left: 5px;
  padding: 2px;
}

.headerNotifItem div div:first-child {
  margin-left: 0px;
  align-self: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.headerNotifItem div:last-child span:last-child {
  font-weight: 600;
}

.facebookEvent {
  color: #3b5998;
  background-color: #3b5998;
}
.twitterEvent {
  color: #55acee;
  background-color: #55acee;
}
.googleEvent {
  color: #28b446;
  background-color: #28b446;
}
.instagramEvent {
  color: #ef3364;
  background-color: #ef3364;
}

.fakeHeader {
  background-color: #ffffff;
  width: 100%;
  padding: 12px 6vw 20px 6vw;
  height: 121px;
  position: relative;
}
.fakeHeader:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 2px 1px rgba(217, 217, 217, 0.5);
}
.notificationBox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  /* background-color: #28B446; */
}

.headerSettings {
  position: relative;
  display: inline-block;
}

.headerSettingsHide {
  position: absolute;
  z-index: 100;
  bottom: 50px;
  right: 25px;
  top: 60px;
  width: 175px;
  height: 75px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 0px 6px 2px rgba(207, 199, 207, 1);
  -moz-box-shadow: 0px 0px 6px 2px rgba(207, 199, 207, 1);
  box-shadow: 0px 0px 6px 2px rgba(207, 199, 207, 1);
  cursor: pointer;
}

/* .headerSettingsHide {
  position: absolute;
  bottom: 50%;
  left: 100;
  right: 0%;
  top: 100%;
  top: 70px;
  right:250px;
  } */

.arrowSettingsOpen {
  transform: rotate(180deg);
  margin: 20px;
}
.arrowSettingsClose {
  transform: rotate(0deg);
  margin: 20px;
}

.prettyContainer {
  margin: 10px 0 0 20px;
  font-style: bold;
  cursor: pointer;
  position: relative;
  z-index: 150;
}

.headerSettingsHide span {
  font-size: 15px;
}

.headerSettingsContainer {
  cursor: pointer;
}

.settingBox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}

/* ---------------------- */
/* ------ VISIPERF ------ */
/* ---------------------- */

.btn-primary {
  float: left;
  background: #8e54e9;
  width: auto;
  height: 42px;
  line-height: 40px;
  padding: 0 50px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-shadow: none;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.btn-primary:hover,
.btn-primary:focus,
.hero_con .btn-primary:hover,
.btn-primary.active,
.btn-primary:active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover {
  text-decoration: none;
  background: #7c36e4;
  color: #fff;
  position: relative;
  border-color: transparent;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}
.form-control {
  background: #ffffff;
  border: 1px solid transparent;
  float: left;
  width: 100%;
  height: 56px;
  padding: 0 15px;
  border: none;
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #93a1ad;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  behavior: url(PIE.htc);
  position: relative;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.form-control:focus {
  border: 1px solid #8e54e9;
  box-shadow: 0 0 13px rgba(142, 84, 233, 0.5);
}

section {
  background: #f6f8fa none repeat scroll 0 0;
  margin-bottom: 47px;
  position: relative;
  width: 100%;
  z-index: 9;
}
.section,
ul {
  width: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

/* ------ Registration-start ------ */
.visiretail_registration {
  overflow: hidden;
  height: 100%;
}
.login {
  height: 100%;
}
.LoginWraper section {
  margin: 0;
}
.login_visiretailTitle {
  font-family: CircularStd;
  font-size: 46px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #273138;
  margin-top: 55px;
  text-align: center;
}
.registration_main {
  padding: 50px 50px 50px 4%;
  position: relative;
  height: 100%;
}
.registration_head {
  display: flex;
  justify-content: center;
  width: auto;
}
.registration_head span {
  font-family: Barlow, sans-serif;
  float: left;
  width: auto;
  font-size: 16px;
  color: #6e6e6e;
  line-height: 120%;
}
.registration_head .btn-primary.login {
  float: right;
  background: transparent;
  border: 1px solid #8e54e9;
  margin: -10px 0 0 22px;
  padding: 0 20px;
  border-radius: 3px;
  box-shadow: none;
  color: #8e54e9;
  font-weight: 500;
  text-transform: inherit;
}
.btn-primary.login:hover,
.btn-primary.login:focus {
  background: #7c36e4;
  color: #ffffff;
}
.registration_con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20%;
}
.form_main {
  width: 100%;
  padding-top: 35px;
}
.form_raw {
  float: left;
  width: 100%;
  padding-top: 12px;
}
.form_raw label {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  line-height: 120%;
}
.form_main .btn-primary {
  width: 100%;
  height: 56px;
  margin: 25px 0 0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 2px 4px 13px rgba(142, 84, 233, 0.5);
}
.form_main .fogot {
  color: #8e54e9;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  font-style: italic;
  font-family: Barlow, sans-serif;
}
.form_col {
  float: left;
  width: 48%;
  margin-right: 3%;
}
.form_col:last-child {
  margin: 0;
}
.registr_slider img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
textarea.form-control {
  height: 110px;
  padding: 10px 15px;
}
.registr_slider .carousel {
  height: 100vh;
  position: relative;
}
.carousel-inner .item {
  height: 100%;
}
.carousel-control {
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  opacity: 1;
}
.carousel-inner {
  cursor: all-scroll;
  height: 100%;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.active.left,
  .carousel-fade .carousel-inner > .item.active.right,
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.prev {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.active,
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.registr_slider {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 0;
}
/* .registr_slider .mySlides:before{ */
.slideShadow {
  background: linear-gradient(
    180.48deg,
    rgba(0, 0, 0, 0.7) 33.42%,
    rgba(131, 75, 230, 0) 98.03%
  );
  /* linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.48) 39.96%, rgba(0, 0, 0, 0) 100%); */
  width: 100%;
  /* min-height: 400px; */
  height: 90%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  transform: rotate(-180deg);
}

.slide_main {
  float: left;
  width: 100%;
  height: 100vh;
  position: relative;
}

.slide_rgpd {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.slide_rgpd-link,
.slide_rgpd-link:hover {
  color: #fff;
  font-style: italic;
  margin-left: 16px;
}
.slide_rgpd-link:hover {
  text-decoration: underline !important;
}

.mySlides {
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}
.mySlides > .slideContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 28%;
  padding-left: 10%;
  padding-right: 24%;
}
.mySlides > .slideContainer > h2 {
  font-family: CircularStd;
  font-size: 44px;
  color: #ffffff;
  z-index: 2;
  margin: 0;
  line-height: 120%;
  white-space: nowrap;
}
.mySlides > .slideContainer > h4 {
  font-family: CircularStd;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 2;
}
.localPub {
  width: 212px;
  height: 47px;
  background: #8e54e9;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  font-family: CircularStd;
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  z-index: 2;
}
.localPubOff {
  padding-left: 90px;
  padding-right: 20px;
  height: 47px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.43);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  font-family: CircularStd;
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #6a6a6a;
  margin-left: -70px;
  z-index: 1;
  white-space: nowrap;
  cursor: pointer;
}

.visibility {
  padding-left: 20px;
  padding-right: 20px;
  height: 47px;
  background: #8e54e9;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  font-family: CircularStd;
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  z-index: 2;
  white-space: nowrap;
}
.visibilityOff {
  padding-right: 90px;
  padding-left: 20px;
  height: 47px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.43);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  font-family: CircularStd;
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #6a6a6a;
  margin-right: -70px;
  z-index: 1;
  white-space: nowrap;
  cursor: pointer;
}

.indicators {
  width: auto;
  left: 0;
  bottom: 100px;
  margin-left: 5%;
  position: absolute;
}
.dot {
  float: left;
  background: rgba(255, 255, 255, 0.5);
  width: 50px;
  height: 3px;
  margin: 0 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  cursor: pointer;
}
.dot.active {
  background: #8e54e9;
}

.thanks_con {
  float: left;
  width: 100%;
  margin-top: 110px;
  text-align: center;
}
.thanks_con .send {
  display: inline-block;
}
.thanks_con p {
  padding-top: 30px;
  font-size: 17px;
  color: #273138;
  line-height: 140%;
}
.thanks_con h5 {
  padding-top: 10px;
  font-size: 18px;
  color: #273138;
  font-weight: 400;
  font-style: italic;
}
.thanks_con span {
  float: right;
  font-family: "Rhesmanisa-Regular";
  font-size: 64px;
  color: #273138;
  font-weight: normal;
  line-height: 120%;
}
/* ------ Registration-end ------ */

.bubbleContainer {
  display: flex;
}
.hoverableTabInfo > .bubbleContainer {
  opacity: 0;
  transition-property: opacity;
  -moz-transition-property: opacity;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-duration: 0.15s;
  -moz-transition-duration: 0.15s;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
}
.hoverableTabInfo:hover > .bubbleContainer {
  display: flex;
  opacity: 1;
}
.bubbleContent {
  background: #273138;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 117%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fefefe;
  padding: 10px 20px;
  position: relative;
}
.bubbleArrowRight {
  background: #273138;
  transform: rotate(135deg);
  width: 8px;
  height: 8px;
  position: absolute;
  right: -4px;
  top: calc(50% - 4px);
}
.bubbleArrowTop {
  background: #273138;
  transform: rotate(135deg);
  width: 8px;
  height: 8px;
  position: absolute;
  top: -4px;
  left: calc(50% - 4px);
}
.placeCornerLabel {
  position: absolute;
  left: -56px;
  top: 30px;
  z-index: 2;
  pointer-events: none;
  max-width: 150px;
  opacity: 0;
  transition-property: opacity;
  -moz-transition-property: opacity;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-duration: 0.15s;
  -moz-transition-duration: 0.15s;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
}
.cornerContainer:hover > .placeCornerLabel {
  opacity: 1;
}

/*******************************************************************************
** @Author:          Benjamin Tollet <Btollet>
** @Email:          benjamin@shoki.io
** @Date:          Monday 01 April 2019 - 11:52:29
** @Filename:        PreviewGoogle.css
**
** @Last modified by:    Florian
** @Last modified time:    Wednesday 17 July 2019 - 16:56:15
*******************************************************************************/
.previewGoogleContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fc;
  border: 1px solid #606060;
  padding-bottom: 30px;
}
.previewGoogleError {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  background-color: #f7dada;
}
.previewGoogleError img {
  width: 15px;
  margin-right: 15px;
}

.previewGoogleMain {
  width: 100%;
  max-width: 406px;
  background-color: #ffffff;
  border: 1px solid #e3e4e5;
  border-radius: 8px;
  padding-top: 20px;
  margin-top: 30px;
}

.googlePictureContainer {
  display: flex;
  max-height: 304px;
  justify-content: center;
  background-color: #000000;
  overflow: hidden;
}
.googlePictureContainer > img {
  max-height: 304px;
}

.previewGoogleText {
  margin: 20px;
  word-break: break-word;
}

.previewGoogleLogo {
  margin-left: 15px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

.previewGoogleTopContainer {
  margin-left: 15px;
  margin-bottom: 10px;
}
.previewGoogleTopName {
  color: #000000;
}
.previewGoogleTopDate {
  color: #616770;
  font-size: 15px;
  font-weight: 400;
}

.previewGoogleIcon {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.previewGoogleIcon > div > img {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 10px;
}
.previewAttachment {
  display: flex;
}

.previewGoogleText > p > span > span > span {
  display: block;
  width: 100%;
  word-wrap: break-word;
}

.stepper {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  counter-reset: step;
  margin-bottom: 30px;
}
.step {
  color: #848484;
  font-weight: bold;
  width: calc(100% / 6);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.step:before {
  counter-increment: step;
  content: counters(step, ".");
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  background-color: #fff;
  border: 2px solid #848484;
  z-index: 10;
}
.step:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #848484;
  top: 18px;
  display: block;
  left: 50%;
}
.step.complete:after {
  background: #6d77dc;
}
.step:last-child:after {
  content: none;
}
.step.active,
.step.complete {
  color: #6d77dc;
}
.step.active:before,
.step.complete:before {
  border-color: #6d77dc;
}
.step.complete:before {
  content: "\002714";
  background: #6d77dc;
  color: #fff;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  min-height: 18px;
  max-height: 18px;
  min-width: 18px;
  max-width: 18px;
  background: #fff;
  color: #fff;
  border: 1px solid #e5e9f0;
  cursor: pointer;
  border-radius: 2px;
  margin-top: 0;
  position: relative;
}
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background: #6d77dc;
  border: none;
  text-align: center;
  border-radius: 2px;
}
input[type="checkbox"]:checked:after {
  content: "\002714";
  font-size: 16px;
  position: absolute;
  right: 4px;
  bottom: -1px;
  border-radius: 2px;
}
input[type="checkbox"]:checked:after {
  content: "✔";
  font-size: 11px;
  position: absolute;
  left: 4px;
  top: 1px;
}

input[type="radio"],
input[type="radio"]:checked {
  border-radius: 50%;
}

input[type="radio"]:checked:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
}
input[type="radio"]:checked:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
}

input[type="range"] {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
}

input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #6d77dc;
  cursor: pointer;
}

.Toastify__toast-container {
  width: 100%;
  font-weight: bold;
  padding: 0;
  top: 0;
  left: 0;
  margin-left: 0;
}
.Toastify__toast {
  align-items: center;
  padding: 1em 2em;
}
.Toastify__toast--info {
  background: rgba(142, 84, 233, 0.8);
}
.Toastify__toast--success {
  background: rgba(45, 204, 77, 0.8);
}
.Toastify__toast--warning {
  background: rgba(2230, 165, 55, 0.8);
}
.Toastify__toast--error {
  background: rgba(244, 0, 9, 0.8);
}
.Toastify__close-button {
  border: 2px solid #fff;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  font-size: 16px;
  line-height: 21px;
  align-self: inherit;
}

.DateRangePicker__CalendarSelection--is-pending,
.DateRangePicker__CalendarSelection {
  background: rgb(142, 84, 233, 0.8);
  border-color: rgb(142, 84, 233, 0.8);
}

.flash-message {
  display: flex;
  align-items: center;
  background: #f3edfd;
  border: 1px solid #8e54e9;
  padding: 10px;
  border-radius: 3px;
}
.flash-message p {
  margin-bottom: 0;
  margin-left: 10px;
  color: #8e54e9;
}
.flash-message strong {
  color: #8e54e9;
}
.flash-message a {
  color: #8e54e9;
  font-weight: bold;
}
.flash-message a:hover {
  text-decoration: underline !important;
}

*******************************************************************************
  **
  BOOSTRAP
  OVERWRITE
  *******************************************************************************/
  p {
  margin: unset !important;
}
a {
  text-decoration: none !important;
}
